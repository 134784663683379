//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useRef } from "react";
import Markdown from "react-markdown";
import { ExampleDataset, ExampleDatasetInstallation } from "../../api/lib";
import CodeBlock from "./CodeBlock";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Button,
  Flex,
  ModalFooter,
  Text,
  OrderedList,
  UnorderedList,
  Heading,
} from "@chakra-ui/react";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { DownloadIcon } from "@chakra-ui/icons";
import { useExportPdf } from "./useExportPdf";

const GuideModal = ({
  onClose,
  isOpen,
  example,
  installation,
}: {
  isOpen: boolean;
  example: ExampleDataset;
  onClose: () => void;
  installation: ExampleDatasetInstallation | undefined;
}) => {
  const { deployment } = useDeploymentStore();
  const exportPdf = useExportPdf(example.name || "Guide");

  const ref = useRef<HTMLDivElement>(null);

  const handleExportPdf = () => {
    if (ref.current) {
      exportPdf(ref.current);
    }
  };

  const onOpenDatabase = () => {
    if (!deployment.status?.endpoint) {
      return;
    }

    const databaseName = installation?.status?.database_name;

    const url = new URL(deployment.status.endpoint);
    url.port = "";

    if (databaseName) {
      url.pathname = `_db/${databaseName}`;
    }

    window.open(url);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{example.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div ref={ref}>
            <Markdown
              children={`${example.guide || ""}`}
              components={{
                code: CodeBlock,
                p: ({ children }) => <Text marginBottom="1rem">{children}</Text>,
                ol: ({ children }) => (
                  <OrderedList marginBlockStart="1em" marginBlockEnd="1em" marginInlineStart="0px" marginInlineEnd="0px" paddingInlineStart="40px">
                    {children}
                  </OrderedList>
                ),
                ul: ({ children }) => (
                  <UnorderedList paddingInlineStart="40px" marginBlockStart="1em" marginBlockEnd="1em">
                    {children}
                  </UnorderedList>
                ),
                h1: ({ children }) => (
                  <Heading as="h1" margin="1em 0">
                    {children}
                  </Heading>
                ),
                h2: ({ children }) => (
                  <Heading as="h2" margin="1rem 0">
                    {children}
                  </Heading>
                ),
                h3: ({ children }) => (
                  <Heading as="h3" margin="1rem 0">
                    {children}
                  </Heading>
                ),
                h4: ({ children }) => (
                  <Heading as="h4" margin="1rem 0">
                    {children}
                  </Heading>
                ),
                h5: ({ children }) => <Heading as="h5">{children}</Heading>,
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Flex gap="2">
            <Button leftIcon={<DownloadIcon />} onClick={handleExportPdf}>
              Export PDF
            </Button>
            <Button colorScheme="green" isDisabled={!!deployment.is_paused} onClick={onOpenDatabase}>
              Open database UI
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { GuideModal };
