//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FlagsProvider } from "flagged";
import _, { filter } from "lodash";
import React, { Component } from "react";
import { match, matchPath, Route, RouteComponentProps } from "react-router-dom";
import api from "../api/api";
import apiClients from "../api/apiclients";
import { ListOptions as ApiListOptions } from "../api/common/v1/common";
import { User as ApiUser } from "../api/iam/v1/iam";
import { IDOptions as ApiIDOptions } from "../api/lib";
import { Organization as ApiOrganization, OrganizationList as ApiOrganizationList } from "../api/resourcemanager/v1/resourcemanager";
import Auth from "../auth/Auth";
import { reportError } from "../errors/reporting";
import { HelpUrl } from "../HelpUrl";
import { Routes } from "../routes";
import { ITracking } from "../tracking/api";
import { PersistentState } from "../util/PersistentState";
import { IWithRefreshProps, withRefresh } from "../util/WithRefresh";
import { ExampleHelpView } from "./help/ExampleHelpView";
import SupportRequest from "./support/SupportRequest";
import { TopMenuInfo } from "./TopMenuInfo";
import { DashboardContextProvider } from "./DashboardContextProvider";
import { MainView } from "./MainView";
import { toggleSentry } from "../util/sentry";
import { DocumentTitle } from "../components/DocumentTitle";

interface IDashboardViewArgs extends IWithRefreshProps, RouteComponentProps {
  //menu related
  topMenuInfo: TopMenuInfo;

  //user related
  user?: ApiUser;
  pendingOrganizationInvites: number;
  reloadOrganizationInvites: () => void;
  onClickLogout: () => void;
  auth: Auth;
  tracking: ITracking;

  //organization related
  organizations?: ApiOrganizationList;
  selectedOrganization?: ApiOrganization;
  onOrganizationSelected: (organizationId: string) => void;
  onNewOrganizationCreated: (organizationId: string) => void;
  onOrganizationDeleted: (organizationId: string) => void;
  onReloadOrganization: () => void;

  //help related
  onClickHelp: () => void;
  onClickExamples: () => void;

  //status page
  onClickStatusPage: () => void;

  // support request
  onClickSupportRequest: () => void;
  // Example help
  isExampleHelpOpen: boolean;
  onCloseExampleHelp: () => void;
}

const DashboardView = ({ ...args }: IDashboardViewArgs) => (
  <DashboardContextProvider
    organizations={args.organizations}
    selectedOrganization={args.selectedOrganization}
    topMenuInfo={args.topMenuInfo}
    onClickLogout={args.onClickLogout}
    reloadOrganizationInvites={args.reloadOrganizationInvites}
    onOrganizationSelected={args.onOrganizationSelected}
    onNewOrganizationCreated={args.onNewOrganizationCreated}
    onOrganizationDeleted={args.onOrganizationDeleted}
    onReloadOrganization={args.onReloadOrganization}
    onClickSupportRequest={args.onClickSupportRequest}
    user={args.user}
    pendingOrganizationInvites={args.pendingOrganizationInvites}
    onClickHelp={args.onClickHelp}
    onClickExamples={args.onClickExamples}
    onClickStatusPage={args.onClickStatusPage}
  >
    <Route render={(props) => <MainView {...args} />} />
    {args.isExampleHelpOpen && <ExampleHelpView {...args} />}
  </DashboardContextProvider>
);

// Interface decribing the properties of the dashboard component
interface IDashboardProps extends IWithRefreshProps, RouteComponentProps {
  user: ApiUser | undefined;
  auth: Auth;
  tracking: ITracking;
  onClickLogout: () => void;
}

type ProjectPath = {
  projectId: string;
};

type DeploymentDetailsPath = ProjectPath & {
  deploymentId: string;
};

type OrganizationPath = {
  organizationId: string;
};

// Interface decribing the state of the dashboard component
interface IDashboardState {
  topMenuInfo: TopMenuInfo;

  user?: ApiUser;
  pendingOrganizationInvites: number;

  organizations?: ApiOrganizationList;
  selectedOrganization?: ApiOrganization;

  privacyConsentLogged: boolean;

  showSupportRequestModal: boolean;

  isExampleHelpOpen: boolean;

  featureFlags?: string[];
}

class Dashboard extends Component<IDashboardProps, IDashboardState> {
  state = {
    topMenuInfo: {},

    user: undefined,
    pendingOrganizationInvites: 0,

    organizations: undefined,
    selectedOrganization: undefined,

    privacyConsentLogged: false,

    showSupportRequestModal: false,

    isExampleHelpOpen: false,
    featureFlags: undefined,
  } as IDashboardState;

  static lastInstance = 0;
  instance = Dashboard.lastInstance++;
  debug = window.DEBUG;
  releasedFeatureFlags = window.RELEASED_FEATURE_FLAGS;

  constructor(props: any) {
    super(props);

    this.state.topMenuInfo = new TopMenuInfo(this.topMenuInfoUpdated);
    try {
      this.state.featureFlags = PersistentState.retrieveFeatureFlags();
    } catch (e) {
      // Ignore
    }

    if (this.debug) {
      console.info(`ctr.reloadOrganizations[${this.instance}]`);
    }
  }

  static findOrganization = (organizations: ApiOrganizationList, organizationId: string): ApiOrganization | undefined => {
    return organizations.items && organizations.items.find((o) => o.id == organizationId);
  };

  log = (msg?: string): void => {
    if (this.debug) {
      console.log(msg);
    }
  };

  topMenuInfoUpdated = () => {
    const topMenuInfo = this.state.topMenuInfo;
    this.setState({ topMenuInfo: topMenuInfo });
  };

  reloadPendingOrganizationInviteCount = async () => {
    try {
      const listOptions = {} as ApiListOptions;
      const organizationInvites = await apiClients.resourceManagerClient.ListMyOrganizationInvites(listOptions);

      if (organizationInvites.items) {
        const count = organizationInvites.items.filter((i) => !i.accepted && !i.rejected).length;
        this.setState({ pendingOrganizationInvites: count });
      }
    } catch (err) {
      reportError(err);
    }
  };

  getOrganizationFromProjectID = async (projectID: string): Promise<string | undefined> => {
    let orgID;
    try {
      const req: ApiIDOptions = {
        id: projectID,
      };
      const projectDetails = await apiClients.resourceManagerClient.GetProject(req);
      const { organization_id: orgId } = projectDetails || {};
      orgID = orgId;
    } catch (err) {
      this.log(`Failed to get organization for project ${projectID}: ${err}`);
      this.log("Falling back to the first organization");
    }
    return orgID;
  };

  /**
   *
   * @param param0 Object container projectPath and deploymentPath
   * @returns organization id
   *
   * This function gets the organization ID using the project path or the deployment path. It will extract the project ID from the URl and use that to get the organization Id. Both deployement and project URLS contains projectID which is why the concatenation below.
   * The match() function returns an array of objects which contains the params object in it. For project path it will be {projectID: #####} and for deployment it will be {projectID: #####, deploymentID: #####}. Since both contains the same, we can concatenate and use the lates obtained project ID.
   * This will then request project details which contains the organization_id and thus, that will finally be returned.
   */
  getOrganizationFromPath = async ({
    projectPath,
    deploymentPath,
    orgPath,
  }: {
    projectPath: match<ProjectPath> | null;
    deploymentPath: match<DeploymentDetailsPath> | null;
    orgPath: match<OrganizationPath> | null;
  }) => {
    if (orgPath) {
      return orgPath.params.organizationId;
    }

    if (!projectPath && !deploymentPath) {
      return undefined;
    }

    const { params = {} } = { ...projectPath, ...deploymentPath };
    const { projectId } = params as ProjectPath;
    return await this.getOrganizationFromProjectID(projectId);
  };

  findOrg = async (orgs: ApiOrganizationList): Promise<{ org: ApiOrganization | undefined; newPath: string | null }> => {
    // User has no orgs, so we can't do anything
    if (!orgs.items) {
      return { org: undefined, newPath: null };
    }

    // It's already in our state, so just return it
    const stateSelectedOrg = this.state.selectedOrganization;
    if (stateSelectedOrg) {
      return { org: stateSelectedOrg, newPath: null };
    }

    const currPath = this.props.location.pathname;

    // Break down the pathname and see if can extract the org ID from that
    const projectPath = matchPath<ProjectPath>(currPath, { path: Routes.dashboard_project });
    const deploymentPath = matchPath<DeploymentDetailsPath>(currPath, { path: Routes.dashboard_project_deployment });
    const orgPath = matchPath<OrganizationPath>(currPath, { path: Routes.dashboard_organization });
    const matchedPath = !!projectPath || !!deploymentPath || !!orgPath;

    const pathOrgId = await this.getOrganizationFromPath({ projectPath, deploymentPath, orgPath });
    const pathOrg = filter(orgs.items, (org) => org.id === pathOrgId)[0];

    // Since the user passed a certain path and we believe it to be valid,
    // let's use this path instead of redirecting
    if (pathOrg) {
      return { org: pathOrg, newPath: null };
    }

    // Path didn't match or org was invalid, so we don't want to consider it
    // Check if we have anything in local storage, if so, use that
    const localStorageOrgId = PersistentState.retrieveSelectedOrganizationId();
    if (localStorageOrgId) {
      const localStorageOrg = Dashboard.findOrganization(orgs, localStorageOrgId);

      // If the local storage org we found is valid, we can return that
      // Since we might be changing the org from the URL version, need to
      // make sure we redirect appropriately
      if (localStorageOrg) {
        return {
          org: localStorageOrg,
          newPath: matchedPath ? Routes.dashboard_organizationWithId(localStorageOrg.id || "") : null,
        };
      }
    }

    // At this point let's do best effort and just pick the first org ID
    // Need to redirect as the URL is unlikely to be valid
    return { org: orgs.items[0], newPath: matchedPath ? Routes.dashboard_organizationWithId(orgs.items[0].id || "") : null };
  };

  reloadOrganizations = async () => {
    const orgs = await apiClients.resourceManagerClient.ListOrganizations({} as ApiListOptions);

    orgs.items = _.orderBy(orgs.items, "name");

    const { org: selectedOrg, newPath } = await this.findOrg(orgs);
    try {
      this.setState(
        {
          organizations: orgs,
          selectedOrganization: selectedOrg,
        },
        () => {
          this.refreshFeatureFlags();
          this.setUserIdentity();
          this.changeRouteIfNeeded();
        }
      );

      // Set the URL based on where we sourced the org ID
      if (newPath) {
        this.props.history.replace(newPath);
      }
    } catch (err) {
      reportError(err);
    }
  };

  reloadUser = async () => {
    try {
      const user = await apiClients.iamClient.GetThisUser();
      this.setState({ user: user }, () => {
        this.refreshFeatureFlags();
        this.setUserIdentity();
        this.changeRouteIfNeeded();
      });
    } catch (err) {
      reportError(err);
    }
  };

  reloadFeatureFlags = async () => {
    try {
      const path = `/featureflags`;
      const req = {} as any;
      const org = this.state.selectedOrganization;
      if (!!org) {
        const tier = org.tier || {};
        req.orgID = org.id;
        req.tierID = tier.id;
      }
      const url = path + api.queryString(req, []);
      const result = await api.get(url, undefined);
      const flags = result.flags || [];
      this.setState({ featureFlags: flags });
      PersistentState.saveFeatureFlags(flags);
    } catch (err) {
      reportError(err);
    }
  };

  refreshFeatureFlags = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadFeatureFlags);
  };

  changeRouteIfNeeded = () => {
    const user = this.state.user;
    if (user) {
      if (user.dashboard_access_denied) {
        this.props.auth.accessDeniedReason = user.dashboard_access_denied_reason || "";
        this.props.history.replace(Routes.accessdenied);
      } else {
        if ((!!user.mobile_phone_needs_verification && !user.mobile_phone_verified) || !user.given_name || !user.family_name || !user.company_name) {
          this.props.history.replace(Routes.onboarding);
        }
        const organizations = this.state.organizations;
        if (organizations) {
          if (!organizations.items || organizations.items.length == 0) {
            this.props.history.replace(Routes.onboarding);
          }
        }
      }
    }
  };

  onNewOrganizationCreated = (organizationId: string) => {
    PersistentState.saveSelectedOrganizationId(organizationId);
    this.setState(
      {
        organizations: undefined,
        selectedOrganization: undefined,
      },
      () => {
        this.props.refreshNow && this.props.refreshNow(this.reloadOrganizations);
        this.refreshFeatureFlags();
      }
    );

    this.props.history.replace(Routes.dashboard_organizationWithId(organizationId));
  };

  onOrganizationSelected = (organizationId: string) => {
    const organizations = this.state.organizations;
    if (organizations) {
      const org = Dashboard.findOrganization(organizations, organizationId);
      if (org) {
        PersistentState.saveSelectedOrganizationId(organizationId);
        this.setState(
          {
            selectedOrganization: org,
          },
          this.refreshFeatureFlags
        );

        // As the URL may not be valid when the org changes
        // ensure we overwrite (unless we're already on the dashboard)
        if (this.props.location.pathname !== Routes.dashboard) {
          this.props.history.replace(Routes.dashboard_organization_detailsWithId(organizationId));
        }
      }
    }
  };

  onOrganizationDeleted = (organizationId: string) => {
    const selectedOrganizationId = PersistentState.retrieveSelectedOrganizationId();
    if (selectedOrganizationId == organizationId) {
      PersistentState.removeSelectedOrganizationId();
      this.setState(
        {
          selectedOrganization: undefined,
        },
        () => {
          this.refreshFeatureFlags();
          this.props.history.replace(Routes.dashboard);
        }
      );
    }
    this.props.refreshNow && this.props.refreshNow(this.reloadOrganizations);
  };

  onReloadOrganization = () => {
    this.setState(
      {
        organizations: undefined,
        selectedOrganization: undefined,
      },
      () => {
        this.props.refreshNow && this.props.refreshNow(this.reloadOrganizations);
        this.refreshFeatureFlags();
      }
    );
  };

  reloadOrganizationInvites = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadPendingOrganizationInviteCount);
  };

  onClickHelp = () => {
    window.open(HelpUrl.url);
  };

  onClickExamples = () => {
    this.setState({ isExampleHelpOpen: true });
  };
  onCloseExampleHelp = () => {
    this.setState({ isExampleHelpOpen: false });
  };

  onClickStatusPage = () => {
    window.open(HelpUrl.status_page);
  };

  componentDidMount() {
    document.body.classList.add("dashboard");
    if (this.props.auth.isAuthenticated()) {
      console.log("Dashboard.componentDidMount - reloadOrganizations");
      this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadUser, 60000);
      this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadOrganizations, 60000);
      this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadPendingOrganizationInviteCount, 60000);
      this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadFeatureFlags, 60000);
    }
    this.updateTopMenu();
    this.registerPrivacyConsentListener();
  }

  componentWillUnmount() {
    document.body.classList.remove("dashboard");
    this.log(`componentWillUnmount[${this.instance}]`);
  }

  componentDidUpdate(prevProps: IDashboardProps) {
    this.updateTopMenu();

    if (prevProps.user !== this.props.user && this.props.refreshNow) {
      this.props.refreshNow(this.reloadUser);
      this.props.refreshNow(this.reloadOrganizations);
      this.props.refreshNow(this.reloadPendingOrganizationInviteCount);
      this.props.refreshNow(this.reloadFeatureFlags);
    }
  }

  setUserIdentity = () => {
    this.props.tracking.setUserIdentity(this.state.user, this.state.selectedOrganization);
    toggleSentry(this.state.user);
  };

  registerPrivacyConsentListener = () => {
    try {
      const _hsq = ((window as any)["_hsq"] = (window as any)["_hsq"] || []);
      _hsq.push([
        "addPrivacyConsentListener",
        (consent: any) => {
          if (!this.state.privacyConsentLogged) {
            console.log(`[${this.instance}] Privacy consent: ${consent.allowed}`);
            this.setState({ privacyConsentLogged: true });
          }
        },
      ]);
    } catch (e) {
      // Ignore errors
    }
  };

  updateTopMenu = () => {
    this.state.topMenuInfo.reset(this.props.location.pathname);
  };

  onClickSupportRequest = () => {
    this.setState({ showSupportRequestModal: true });
  };

  onClickCloseSupportRequest = () => {
    this.setState({ showSupportRequestModal: false });
  };

  render() {
    const titlePrefix = this.state.topMenuInfo.getTitlePrefix();
    let title = this.state.topMenuInfo.getTitle();
    if (titlePrefix) {
      title = `${titlePrefix}: ${title}`;
    }
    const selectedOrganization = this.state.selectedOrganization;
    const key = `dashboard-${selectedOrganization ? selectedOrganization.id : "-"}`;

    return (
      <FlagsProvider features={_.union(this.releasedFeatureFlags, this.state.featureFlags || [])}>
        <div>
          {this.state.showSupportRequestModal && (
            <SupportRequest
              {...this.props}
              {...this.state}
              showSupportRequestModal={this.state.showSupportRequestModal}
              onClose={this.onClickCloseSupportRequest}
              organization={this.state.selectedOrganization}
              user={this.state.user}
            />
          )}
          {this.state.showSupportRequestModal && (
            <SupportRequest
              {...this.props}
              {...this.state}
              showSupportRequestModal={this.state.showSupportRequestModal}
              onClose={this.onClickCloseSupportRequest}
              organization={this.state.selectedOrganization}
              user={this.state.user}
            />
          )}
          <DocumentTitle title={title}>
            <DashboardView
              {...this.props}
              {...this.state}
              key={key}
              reloadOrganizationInvites={this.reloadOrganizationInvites}
              onClickLogout={this.props.onClickLogout}
              onOrganizationSelected={this.onOrganizationSelected}
              onNewOrganizationCreated={this.onNewOrganizationCreated}
              onOrganizationDeleted={this.onOrganizationDeleted}
              onReloadOrganization={this.onReloadOrganization}
              onClickHelp={this.onClickHelp}
              onClickExamples={this.onClickExamples}
              onCloseExampleHelp={this.onCloseExampleHelp}
              onClickStatusPage={this.onClickStatusPage}
              onClickSupportRequest={this.onClickSupportRequest}
            />
          </DocumentTitle>
        </div>
      </FlagsProvider>
    );
  }
}

export default withRefresh()(Dashboard);
