//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchExamplesList = () => {
  const { selectedOrganization } = useDashboardContext();

  const fetchExamples = async () => {
    const list = await apiClients.exampleClient.ListExampleDatasets({ organization_id: selectedOrganization.id });

    return list;
  };

  return useQuery([selectedOrganization.id, "examples"], fetchExamples, {
    enabled: !!selectedOrganization.id,
  });
};
