//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";

export const useFetchExamplesInstallationsList = () => {
  const { deployment } = useDeploymentStore();

  const fetchExamplesInstallations = async () => {
    const list = await apiClients.exampleClient.ListExampleDatasetInstallations({ deployment_id: deployment.id });

    return list;
  };

  return useQuery([deployment.id, "examples-installations"], fetchExamplesInstallations, {
    refetchInterval: 5 * 1000, // 5 seconds
    enabled: !!deployment.id,
  });
};
