//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { RadioGroup, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { PageSpinner } from "../../components/PageSpinner";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { DiskRateLimitMessage } from "./DiskRateLimitMessage";
import { MinSizeForDownsizingAlert } from "./MinSizeForDownsizingAlert";
import { NodeSizeRow } from "./NodeSizeRow";
import { useFetchNodeSizes } from "./useFetchNodeSizes";
import { useReinitializeNodeSize } from "./useReinitializeNodeSize";

export const NodeSizeSelectorTable = ({ isDisabled }: { isDisabled?: boolean }) => {
  useReinitializeNodeSize();
  const [regionField] = useField("regionId");
  const [modelField] = useField("modelId");
  const [nodeSizeIdField] = useField("nodeSizeId");
  const { isFetching } = useFetchNodeSizes({
    regionId: regionField.value,
    modelId: modelField.value,
  });
  const isSingle = modelField.value === "developer" || modelField.value === "free";
  const { data: deployment } = useFetchDeploymentForEdit();
  const { disk_rate_limit_active: diskRateLimitActive } = deployment || {};
  const isNodeSizeSelectionDisabled = isDisabled || !!diskRateLimitActive;
  // if multiple nodesizes have the same name, ), old impl shows only one, and allow user to select 'general' or 'low' CPU per node
  // in new impl this shows up as 0.5 vCPU per node vs 1 vCPU per node
  return (
    <RadioGroup isDisabled={isNodeSizeSelectionDisabled} value={nodeSizeIdField.value} position="relative" minHeight="200px">
      <PageSpinner isLoading={isFetching} />
      <MinSizeForDownsizingAlert />
      <DiskRateLimitMessage target="Node size" diskRateLimitActive={diskRateLimitActive} />
      <Table
        __css={{
          tableLayout: "fixed",
          width: "100%",
        }}
      >
        <Thead>
          <Tr>
            <Th width="10"></Th>
            <Th width="120px">Node Size</Th>
            <Th width="140px">{isSingle ? "vCPU" : "vCPU/Node"}</Th>
            <Th width="148px">{isSingle ? "Memory" : "Memory/Node"}</Th>
            <Th width="240px">{isSingle ? "Disk" : "Disk/Node"}</Th>
          </Tr>
        </Thead>
        <Tbody>
          <NodeSizeSelectorTableBody isNodeSizeSelectionDisabled={isNodeSizeSelectionDisabled} />
        </Tbody>
      </Table>
    </RadioGroup>
  );
};

const NodeSizeSelectorTableBody = ({ isNodeSizeSelectionDisabled }: { isNodeSizeSelectionDisabled: boolean }) => {
  const [regionField] = useField("regionId");
  const [modelField] = useField("modelId");
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: regionField.value,
    modelId: modelField.value,
  });
  return (
    <>
      {nodeSizes?.items?.map((nodeSize) => {
        return <NodeSizeRow key={nodeSize.id} nodeSize={nodeSize} isNodeSizeSelectionDisabled={isNodeSizeSelectionDisabled} />;
      })}
    </>
  );
};
