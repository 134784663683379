//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Radio, Td, Tr } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { NodeSize } from "../../api/lib";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useGetNodeDiskSizeInfo, NodeDiskSizeText } from "./NodeDiskSizeText";

export const NodeSizeRow = ({ nodeSize, isNodeSizeSelectionDisabled }: { nodeSize: NodeSize; isNodeSizeSelectionDisabled: boolean }) => {
  const { values, setFieldValue } = useFormikContext<NewDeploymentFormValues>();
  const { isDiskSizeExceedingMax } = useGetNodeDiskSizeInfo({ nodeSize });

  if (!nodeSize.id) {
    return null;
  }

  const cpuCount = getCPUCount(nodeSize);

  return (
    <Tr
      key={nodeSize.id}
      onClick={() => {
        // if disk size is exceeding max, do nothing
        if (isDiskSizeExceedingMax) {
          return;
        }
        // if disabled or already selected, do nothing
        if (isNodeSizeSelectionDisabled || values.nodeSizeId === nodeSize.id) {
          return;
        }
        setFieldValue("nodeSizeId", nodeSize.id);
        setFieldValue("customDiskSize", {});
      }}
      _hover={{
        bg: "green.50",
        cursor: isNodeSizeSelectionDisabled || isDiskSizeExceedingMax ? "not-allowed" : "pointer",
      }}
      backgroundColor={nodeSize.id === values.nodeSizeId ? "green.100" : ""}
    >
      <Td>
        <Radio value={nodeSize.id} />
      </Td>
      <Td>{nodeSize.name}</Td>
      <Td>{cpuCount}</Td>
      <Td>{nodeSize.memory_size} GB</Td>
      <Td
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <NodeDiskSizeText nodeSize={nodeSize} isNodeSizeSelectionDisabled={isNodeSizeSelectionDisabled} />
      </Td>
    </Tr>
  );
};

// CPU count logic from DeploymentModelPrice.tsx
const getCPUCount = (nodeSize: NodeSize) => {
  if (!nodeSize.cpu_size || !nodeSize.memory_size) return 0;
  const { cpu_size: cpuSize, memory_size: memorySize } = nodeSize;
  const cpuSizeNumber = Number(cpuSize.replace("c", ""));
  return memorySize / cpuSizeNumber;
};
