//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useRef } from "react";
import apiClients from "../../api/apiclients";
import { ExampleDataset } from "../../api/lib";
import { useTrackingContext } from "../../tracking/TrackingContextProvider";
import { useToast, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react";
import { useFetchExamplesInstallationsList } from "./useFetchExamplesInstallationsList";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useMutation } from "@tanstack/react-query";

const useDeleteExampleDatasetInstallation = ({ installationId }: { installationId: string }) => {
  return useMutation({
    mutationFn: async () => {
      await apiClients.exampleClient.DeleteExampleDatasetInstallation({ id: installationId });
    },
  });
};

const UninstallExampleAlertDialog = ({
  example,
  installationId,
  isOpen,
  onClose,
}: {
  example: ExampleDataset;
  installationId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { mutateAsync: onDeleteInstallation, isLoading: isDeletingInstallation } = useDeleteExampleDatasetInstallation({ installationId });
  const { refetch: refetchInstallations } = useFetchExamplesInstallationsList();
  const { tracking } = useTrackingContext();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const { deployment } = useDeploymentStore();

  const onUninstallConfirmed = async () => {
    try {
      tracking.trackExampleDatasetInstallationDeleted(deployment.id, example.id);
      await onDeleteInstallation();
      refetchInstallations();
      onClose();
    } catch (e) {
      toast({
        title: `Failed to remove example dataset ${example.name}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Example
          </AlertDialogHeader>

          <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onUninstallConfirmed} ml={3} isLoading={isDeletingInstallation}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export { UninstallExampleAlertDialog };
