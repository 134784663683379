//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Alert, AlertIcon } from "@chakra-ui/alert";
import { Box, Grid, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect } from "react";
import { Loading, Section, SectionContent } from "../../ui/lib";
import ExampleRow from "./ExampleRow";
import { useFetchExamplesInstallationsList } from "./useFetchExamplesInstallationsList";
import { useFetchExamplesList } from "./useFetchExamplesList";

const ExamplesList = () => {
  const toast = useToast();

  const { data: examples, isLoading: isLoadingExamples, isError: isExamplesFetchError } = useFetchExamplesList();
  const { isLoading: isLoadingInstallations, isError: isInstallationsFetchError } = useFetchExamplesInstallationsList();

  const hasExamples = !!examples;

  useEffect(() => {
    if (isExamplesFetchError) {
      toast({
        title: "Failed to fetch examples",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isExamplesFetchError]);

  useEffect(() => {
    if (isInstallationsFetchError) {
      toast({
        title: "Failed to fetch examples installed",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isInstallationsFetchError]);

  if (isLoadingExamples || isLoadingInstallations) {
    return <Loading />;
  }

  if (!hasExamples) {
    return (
      <Section>
        <SectionContent>
          <p className="para">No examples found.</p>
        </SectionContent>
      </Section>
    );
  }

  return (
    <Section>
      <SectionContent>
        <div>
          <Box width="100%">
            <Alert status="info" marginBottom={10} borderRadius="md">
              <AlertIcon />
              <Text>
                These examples demonstrate some of the features of ArangoDB. You can install each example in your deployment. A new database will be created in
                your deployment to avoid mixing example data with your existing data.
              </Text>
            </Alert>
          </Box>
          <Grid gap={4} templateColumns="repeat(auto-fill, minmax(350px, 1fr))">
            {examples.items?.map((example) => <ExampleRow key={example.id} example={example} />)}
          </Grid>
        </div>
      </SectionContent>
    </Section>
  );
};

export { ExamplesList };
