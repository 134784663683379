//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { jsPDF } from "jspdf";

export const useExportPdf = (fileName: string) => {
  const exportPdf = (html: HTMLElement) => {
    const pdf = new jsPDF("portrait", "px", "a4");
    pdf.advancedAPI(() => {
      pdf.html(html, {
        callback: (pdf) => {
          pdf.save(`${fileName}.pdf`);
        },
        margin: [10, 10, 10, 10],
        autoPaging: "text",
        x: 0,
        y: 0,
        width: 425, // target width in the PDF document
        windowWidth: 1080, // window width in CSS pixels
      });
    });
  };

  return exportPdf;
};
