//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { InfoIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, FlexProps, Grid, Heading, Spinner, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React from "react";
import { NavLink } from "react-router-dom";
import { LinkText } from "../../components/LinkText";
import { Routes } from "../../routes";
import { useDashboardContext } from "../DashboardContextProvider";
import { ProviderIconView } from "../deployment/ProviderIcon";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import PriceDetailsModal from "./PriceDetailsModal";
import { useFetchCACertificates } from "./useFetchCACertificates";
import { useFetchDeploymentModels } from "./useFetchDeploymentModels";
import { useFetchDiskPerformance } from "./useFetchDiskPerformance";
import { useFetchIPAllowlists } from "./useFetchIPAllowlists";
import { useFetchNodeSizes } from "./useFetchNodeSizes";
import { useFetchProviders } from "./useFetchProviders";
import { useFetchRegions } from "./useFetchRegions";
import { useFetchSupportPlans } from "./useFetchSupportPlans";
import { usePriceSummary } from "./usePriceSummary";
import { useSizeSummary } from "./useSizeSummary";

export const SummaryBox = () => {
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const { data: providers } = useFetchProviders();
  const { data: regions, isFetching: isFetchingRegions } = useFetchRegions({ providerId: values.providerId, modelId: values.modelId });
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: values.regionId,
    modelId: values.modelId,
  });
  const nodeSize = nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId);
  const nodeDiskSize = nodeSize?.min_disk_size || 0;
  const { data: diskPerformances, isFetching: isFetchingDiskPerformance } = useFetchDiskPerformance({
    regionId: values.regionId,
    modelId: values.modelId,
    nodeDiskSizeId: values.nodeSizeId,
    nodeDiskSize,
  });

  const diskPerformance = diskPerformances?.items?.find((dp) => dp.id === values.diskPerformanceId);

  const providerName = providers?.items?.find((p) => p.id === values.providerId)?.name;

  const { cpuSize, deploymentSize, isFetching: isFetchingDeploymentSize } = useSizeSummary();
  const { data: caCertificates } = useFetchCACertificates();
  const caCertificate = caCertificates?.items?.find((c) => c.id === values.certificate);
  const { data: supportPlans } = useFetchSupportPlans({ modelId: values.modelId });
  const supportPlan = supportPlans?.items?.find((sp) => sp.id === values.supportPlanId);
  const { data: deploymentModels } = useFetchDeploymentModels();
  const model = deploymentModels?.items?.find((m) => m.id === values.modelId);
  const { data: ipAllowLists } = useFetchIPAllowlists();
  const ipAllowlist = ipAllowLists?.items?.find((ip) => ip.id === values.ipAllowlistId);

  return (
    <Box paddingLeft="2">
      <Flex direction="column" gap="2" border="2px solid" borderColor="gray.200" p="4" borderRadius="md">
        <Heading size="md">Summary</Heading>
        <Text>This setup results in an ArangoDB {values.modelId === "developer" ? "single instance" : "cluster"}</Text>
        <Grid gap="4" templateColumns="1fr 0.5fr">
          <Flex gap="2">
            <Box flexShrink={0} width="10" height="10" title={providerName}>
              <ProviderIconView providerId={values.providerId} />
            </Box>
            <SummaryItem heading="Region" value={regions?.items?.find((r) => r.id === values.regionId)?.location} isLoading={isFetchingRegions} />
          </Flex>
          <PriceSummaryItem />
        </Grid>
        <Divider />
        <Heading size="xs">Resources</Heading>
        <Grid templateColumns="1fr 1fr" gap="0">
          <SummaryItem value={values.modelId === "flexible" ? values.servers?.dbservers : values.numberOfNodes} heading="Max replication factor" />
          <SummaryItem value={deploymentSize?.total_memory_size} isLoading={isFetchingDeploymentSize} heading="Total Memory" unit="GB" />
          <SummaryItem value={deploymentSize?.total_disk_size} isLoading={isFetchingDeploymentSize} heading="Total Storage" unit="GB" />
          <SummaryItem value={cpuSize?.name} heading="CPU" />
        </Grid>
        <PriceInfo />
        <Divider />
        <Heading size="xs">Configuration</Heading>
        <Grid templateColumns="1fr 1fr" gap="2">
          {values.customImage ? (
            <SummaryItem value={values.customImage} heading="Custom Image" />
          ) : (
            <SummaryItem value={values.dbVersion} heading="ArangoDB Version" />
          )}
          <SummaryItem value={model?.name} heading="Model" />
          <SummaryItem value={nodeSize?.name} heading="Node size" unit="GB" />
          <SummaryItem value={diskPerformance?.name} heading="Disk Performance" isLoading={isFetchingDiskPerformance} />
          <SummaryItem value={caCertificate?.name} heading="CA Certificate" />
          <SummaryItem value={ipAllowlist?.name} heading="IP Allowlist" />
          <SummaryItem value={supportPlan?.name} heading="Support Plan" />
        </Grid>
      </Flex>
    </Box>
  );
};

const SummaryItem = React.forwardRef(
  (
    {
      value,
      heading,
      isLoading,
      unit,
      containerProps,
    }: {
      value: string | number | undefined;
      heading: React.ReactNode;
      isLoading?: boolean;
      unit?: string;
      containerProps?: FlexProps;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const finalValue = unit && value ? `${value} ${unit}` : value;
    return (
      <Flex direction="column" padding="1" borderRadius="md" {...containerProps} ref={ref}>
        <Text fontSize="xs" color="gray.800">
          {heading}
        </Text>
        {isLoading ? <Spinner size="sm" /> : <Text fontSize="sm">{finalValue || "-"}</Text>}
      </Flex>
    );
  }
);

const PriceInfo = () => {
  const { isFreeTier, isPerpetualFreeTrialAvailable, selectedOrganization } = useDashboardContext();
  const [modelIdField] = useField("modelId");
  const freeDays = isPerpetualFreeTrialAvailable ? 30 : 14;
  if (isFreeTier) {
    return (
      <Flex direction="column" gap="1">
        <Text fontSize="xs">You can try this deployment for free for {freeDays} days. No credit card required.</Text>
        <LinkText as={NavLink} to={Routes.dashboard_organization_billingWithId(selectedOrganization?.id || "")}>
          Add a payment method to unlock more &amp; larger deployments.
        </LinkText>
        <Text fontSize="xs">After you add a payment method to stop expiration, you will be billed hourly.</Text>
        <Text fontSize="xs">Hourly price excludes variable costs such data transfer, backup storage and taxes.</Text>
      </Flex>
    );
  }
  if (modelIdField.value === "free") {
    return <Text fontSize="xs">You can try this deployment for free for 30 days. No credit card required.</Text>;
  }
  return (
    <Flex direction="column" gap="2">
      <Text fontSize="xs">You will be billed hourly and can terminate this deployment at any time.</Text>
      <Text fontSize="xs">Hourly price excludes variable costs such data transfer, backup storage and taxes.</Text>
    </Flex>
  );
};

const useFinalPrice = ({ price }: { price: string }) => {
  const { isFreeTier, isPerpetualFreeTrialAvailable } = useDashboardContext();
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const isFreeModel = values.modelId === "free";

  // ideally only this should suffice
  if (isFreeModel) {
    return "Free for 30 days";
  }

  // adding for now, can remove later if not needed
  // ideally with backend data constrains
  // the user should always be on free model
  // if perpetual free trial is available
  if (isPerpetualFreeTrialAvailable && isFreeTier) {
    return "Free for 30 days";
  }

  if (isFreeTier) {
    return "Free for 14 days";
  }
  return price;
};

const PriceSummaryItem = () => {
  const { price, priceHeading, isFetching: isFetchingPrice } = usePriceSummary();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isPrepaid } = useFetchDeploymentForEdit();
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const { isFreeTier } = useDashboardContext();
  const isFreeModel = values.modelId === "free";
  const finalPrice = useFinalPrice({ price });
  return (
    <>
      <Tooltip shouldWrapChildren hasArrow label="Click for details." placement="top">
        <SummaryItem
          value={isPrepaid ? "Prepaid" : finalPrice}
          containerProps={{
            onClick: () => onOpen(),
            _hover: {
              backgroundColor: "gray.100",
              cursor: "pointer",
            },
          }}
          heading={
            <Flex alignItems="center" gap="1">
              <Text fontSize="xs">{priceHeading}</Text>
              <InfoIcon />
            </Flex>
          }
          isLoading={isFetchingPrice}
          unit={isPrepaid || isFreeTier || isFreeModel ? "" : "/hr"}
        />
      </Tooltip>
      <PriceDetailsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
