//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Button, Flex, FormControl, FormErrorMessage, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { isUndefined } from "lodash";
import React from "react";
import { NodeSize } from "../../api/lib";
import { SingleSelect } from "../../components/SingleSelect";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useCalculateDiskSizesForDownsizing } from "./useCalculateDiskSizesForDownsizing";

export const useGetNodeDiskSizeInfo = ({ nodeSize }: { nodeSize: NodeSize }) => {
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const { minSizeForDownsizing } = useCalculateDiskSizesForDownsizing();
  const { data: deployment } = useFetchDeploymentForEdit();
  const { node_disk_size: currentNodeDiskSize = nodeSize.min_disk_size || 0 } = deployment?.model || {};

  const minSizeAllowed =
    minSizeForDownsizing > (nodeSize.min_disk_size || 0)
      ? Math.min(Math.max(nodeSize.min_disk_size || 0, minSizeForDownsizing), currentNodeDiskSize)
      : nodeSize.min_disk_size || 0;

  const customDiskSize = nodeSize.id ? values.customDiskSize[nodeSize.id] : undefined;
  const hasSingleDiskSize = nodeSize.disk_sizes && nodeSize.disk_sizes.length === 1;
  const initialDiskSize = hasSingleDiskSize ? nodeSize.disk_sizes?.[0] : minSizeAllowed;
  const isCustomSize = !isUndefined(customDiskSize) && customDiskSize !== initialDiskSize;
  const currentDiskSize = isCustomSize ? customDiskSize : initialDiskSize || 0;
  const isDiskSizeExceedingMax = currentDiskSize > (nodeSize.max_disk_size || 0);

  return {
    minSizeAllowed,
    initialDiskSize,
    isCustomSize,
    currentDiskSize,
    isDiskSizeExceedingMax,
  };
};

export const NodeDiskSizeText = ({ nodeSize, isNodeSizeSelectionDisabled }: { nodeSize: NodeSize; isNodeSizeSelectionDisabled: boolean }) => {
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const [isDiskCustomizationActive, setIsDiskCustomizationActive] = React.useState(false);
  const customDiskSize = values.customDiskSize[nodeSize.id || ""];

  const { initialDiskSize, isCustomSize, currentDiskSize, isDiskSizeExceedingMax, minSizeAllowed } = useGetNodeDiskSizeInfo({ nodeSize });

  if (!nodeSize.id) {
    return null;
  }

  if (isDiskSizeExceedingMax) {
    return <Text color="red.500">Your current disk usage is too high for this node size</Text>;
  }

  const isMinMaxDifferent = minSizeAllowed !== nodeSize.max_disk_size;
  const hasDiskSizes = !!nodeSize.disk_sizes && nodeSize.disk_sizes.length > 0;
  const hasSingleDiskSize = nodeSize.disk_sizes && nodeSize?.disk_sizes.length === 1;
  // show the customization option when it's a new deployment AND
  // - disk sizes are available, and there is more than one disk size
  // - OR mixed disk sizes are available (min and max disk size are different)
  const showDiskCustomizationButton = isMinMaxDifferent || (hasDiskSizes && !hasSingleDiskSize);
  const defaultDiskSize = hasSingleDiskSize ? nodeSize.disk_sizes?.[0] : nodeSize.min_disk_size;
  const isDefaultDiskSize = defaultDiskSize === initialDiskSize;

  // - if disk sizes are available as an array, only allow customization on those values in the array (between min and max disk size)
  // - if disk sizes are not available, simply allow setting it to any value in between min and max
  if (isDiskCustomizationActive) {
    return (
      <NodeDiskSizeInput
        minSizeAllowed={minSizeAllowed}
        nodeSize={nodeSize}
        setIsDiskCustomizationActive={setIsDiskCustomizationActive}
        currentDiskSize={currentDiskSize}
      />
    );
  }

  const diskSizeText = isCustomSize ? `${customDiskSize || 0} GB (custom)` : isDefaultDiskSize ? `${defaultDiskSize} GB` : `${initialDiskSize} GB (custom)`;

  return (
    <Flex gap="2" alignItems="center">
      <Text>{diskSizeText}</Text>
      {showDiskCustomizationButton && !isDiskCustomizationActive && (
        <Button
          isDisabled={isNodeSizeSelectionDisabled}
          variant="ghost"
          colorScheme="blue"
          size="xs"
          onClick={(event) => {
            event.stopPropagation();
            setIsDiskCustomizationActive((prev) => !prev);
          }}
        >
          Customize
        </Button>
      )}
    </Flex>
  );
};

const NodeDiskSizeInput = ({
  nodeSize,
  setIsDiskCustomizationActive,
  currentDiskSize,
  minSizeAllowed,
}: {
  nodeSize: NodeSize;
  setIsDiskCustomizationActive: (value: boolean) => void;
  currentDiskSize: number | undefined;
  minSizeAllowed: number;
}) => {
  const { setFieldValue } = useFormikContext<NewDeploymentFormValues>();

  const getError = (nodeDiskSizeInputValue: string | number | undefined) => {
    if (!nodeSize.max_disk_size || !minSizeAllowed) {
      return;
    }
    if (!nodeDiskSizeInputValue || (nodeSize.id && (nodeDiskSizeInputValue < minSizeAllowed || nodeDiskSizeInputValue > nodeSize.max_disk_size))) {
      return `Disk size must be between ${minSizeAllowed} and ${nodeSize.max_disk_size} GB`;
    }
  };
  const [nodeDiskSizeInputValue, setNodeDiskSizeInputValue] = React.useState<string | number | undefined>(currentDiskSize);
  const onNodeDiskSizeChange = (value: string | number | undefined) => {
    const size = value && typeof value === "string" ? parseInt(value, 10) : value;
    if (!nodeSize.id) {
      return;
    }
    setNodeDiskSizeInputValue(size);
  };

  const onSave = () => {
    if (!nodeSize.id) {
      return;
    }
    setFieldValue("customDiskSize", { [nodeSize.id]: nodeDiskSizeInputValue });
    setIsDiskCustomizationActive(false);
  };
  const onCancel = () => {
    setIsDiskCustomizationActive(false);
  };
  const error = getError(nodeDiskSizeInputValue);
  return (
    <Flex gap="2" alignItems="center">
      <NodeDiskSizeInputElement
        nodeSize={nodeSize}
        error={error}
        nodeDiskSizeInputValue={nodeDiskSizeInputValue}
        onCancel={onCancel}
        onSave={onSave}
        onNodeDiskSizeChange={onNodeDiskSizeChange}
      />
      <Button size="xs" variant="ghost" colorScheme="gray" onClick={() => onCancel()}>
        Cancel
      </Button>
      <Button isDisabled={!!error} size="xs" variant="ghost" colorScheme="blue" onClick={() => onSave()}>
        Save
      </Button>
    </Flex>
  );
};

const NodeDiskSizeInputElement = ({
  nodeSize,
  error,
  nodeDiskSizeInputValue,
  onCancel,
  onSave,
  onNodeDiskSizeChange,
}: {
  nodeSize: NodeSize;
  error: string | undefined;
  nodeDiskSizeInputValue: string | number | undefined;
  onCancel: () => void;
  onSave: () => void;
  onNodeDiskSizeChange: (value: string | number | undefined) => void;
}) => {
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      onCancel();
    }
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      if (!!error) {
        return;
      }
      onSave();
    }
  };
  if (!nodeSize.disk_sizes || (nodeSize.disk_sizes && nodeSize.disk_sizes.length === 0)) {
    return (
      <FormControl width="auto" isInvalid={!!error}>
        <InputGroup size="sm" maxWidth={"100px"}>
          <Input
            autoFocus
            type="number"
            value={nodeDiskSizeInputValue}
            onKeyDown={onKeyDown}
            backgroundColor="white"
            onChange={(event) => {
              onNodeDiskSizeChange(event.target.value);
            }}
          />
          <InputRightElement gap="2" width="52px">
            <Text>GB</Text>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage maxWidth="200px">{error}</FormErrorMessage>
      </FormControl>
    );
  }

  const options = nodeSize.disk_sizes?.map((size) => ({ label: String(size), value: String(size) }));
  const value = options?.find((option) => option.value === String(nodeDiskSizeInputValue));
  return (
    <Flex gap="2" alignItems="center" flexShrink={0}>
      <FormControl width="auto" flexShrink={0} isInvalid={!!error}>
        <SingleSelect
          value={value}
          onChange={(value) => {
            onNodeDiskSizeChange(value?.value);
          }}
          options={options}
          onKeyDown={onKeyDown}
        />
        <FormErrorMessage maxWidth="200px">{error}</FormErrorMessage>
      </FormControl>
      <Text>GB</Text>
    </Flex>
  );
};
