//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Code,
  Divider,
  Flex,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import apiClients from "../../api/apiclients";
import { ExampleDataset } from "../../api/lib";
import { useTrackingContext } from "../../tracking/TrackingContextProvider";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { DeploymentPausedModal } from "../pause/DeploymentPausedModal";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { CopyRootPassword } from "./CopyRootPassword";
import { GuideModal } from "./GuideModal";
import { UninstallExampleAlertDialog } from "./UninstallExampleAlertDialog";
import { useFetchExamplesInstallationsList } from "./useFetchExamplesInstallationsList";

const useCreateExampleDatasetInstallation = ({ exampleId }: { exampleId: string }) => {
  const { deployment } = useDeploymentStore();

  return useMutation({
    mutationFn: async () => {
      await apiClients.exampleClient.CreateExampleDatasetInstallation({
        deployment_id: deployment.id,
        exampledataset_id: exampleId,
      });
    },
  });
};

const ExampleRowList = ({ example }: { example: ExampleDataset }) => {
  const { checkDeploymentPermission } = useCheckPermissionByUrl();
  const { mutateAsync: onCreateInstallation, isLoading: isCreatingInstallation } = useCreateExampleDatasetInstallation({ exampleId: example.id || "" });
  const { deployment } = useDeploymentStore();
  const { tracking } = useTrackingContext();
  const { onResumeDeployment } = useProjectDetailsContext();
  const { isOpen: isUninstallAlertDialogOpen, onOpen: onOpenUninstallAlertDialog, onClose: onCloseUninstallAlertDialog } = useDisclosure();
  const { isOpen: isGuideOpen, onOpen: onOpenGuide, onClose: onCloseGuide } = useDisclosure();
  const toast = useToast();
  const { refetch: refetchInstallations, data: installationsList } = useFetchExamplesInstallationsList();

  const installation = installationsList?.items?.find((installation) => installation.exampledataset_id === example.id);
  const { status: installationStatus, is_deleted: isUninstalling } = installation || {};
  const { is_available, is_failed, database_name: databaseName, demo_url: demoUrl } = installationStatus || {};

  const isInstallAllowed = checkDeploymentPermission("example.exampledatasetinstallation.create");
  const isUninstallAllowed = checkDeploymentPermission("example.exampledatasetinstallation.delete");
  const canUninstall = !!installation && isUninstallAllowed;
  const hasInstallation = !!installation;
  const hasDemo = !!is_available && !isUninstalling && !!demoUrl;
  const isCreatingDatabase = hasInstallation && !is_available && !is_failed;
  const isInstalling = hasInstallation && !is_available && !is_failed && !!databaseName;
  const isBusy = isCreatingDatabase || isInstalling || isUninstalling;
  const isEndpointAvailable = !!deployment.status?.endpoint;

  const onInstall = async () => {
    try {
      tracking.trackExampleDatasetInstallationCreated(deployment.id, example.id);
      await onCreateInstallation();

      refetchInstallations();
    } catch (e) {
      toast({
        title: `Failed to create example dataset installation: ${e}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onOpenDatabase = () => {
    if (!deployment.status?.endpoint) {
      return;
    }

    const url = new URL(deployment.status.endpoint);
    url.port = "";

    if (databaseName) {
      url.pathname = `_db/${databaseName}`;
    }

    window.open(url);
  };

  const getLoadingText = () => {
    if (isInstalling) {
      return "Installing";
    }
    if (isCreatingDatabase) {
      return "Creating DB";
    }
    return "Getting ready";
  };

  const onClickInstall = () => {
    if (!!deployment.is_paused) {
      return;
    }
    onInstall();
  };

  const onClickOpenDatabase = () => {
    if (!!deployment.is_paused) {
      return;
    }
    onOpenDatabase();
  };

  return (
    <Card>
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">{example.name}</Heading>
          {canUninstall && !isBusy && (
            <DeploymentPausedModal
              onResumeDeployment={() => onResumeDeployment(deployment.id || "")}
              onClick={onOpenUninstallAlertDialog}
              trigger={
                <Button variant="outline" colorScheme="red" size="xs">
                  Uninstall
                </Button>
              }
            />
          )}
        </Flex>
      </CardHeader>
      <GuideModal example={example} onClose={onCloseGuide} isOpen={isGuideOpen} installation={installation} />

      <CardBody>
        <Flex direction="column" justifyContent="space-between" height="100%">
          <Text>{example.description}</Text>

          {!isBusy && hasInstallation && !!databaseName && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Divider marginY="10px" />
              <Text>
                Installed in database{" "}
                <Code fontWeight="bold" background="white">
                  {databaseName}
                </Code>
              </Text>
              <CopyRootPassword />
            </Box>
          )}
        </Flex>
      </CardBody>
      <CardFooter>
        <ButtonGroup width="100%" justifyContent="flex-end" gap={2}>
          <Button isDisabled={isBusy} onClick={onOpenGuide}>
            Guide
          </Button>
          {hasDemo && <Button onClick={() => window.open(demoUrl || "")}>Demo</Button>}
          {isInstallAllowed && (
            <DeploymentPausedModal
              onResumeDeployment={() => onResumeDeployment(deployment.id || "")}
              trigger={
                !hasInstallation ? (
                  <Button disabled={isCreatingInstallation} colorScheme="green" onClick={onClickInstall}>
                    Install
                  </Button>
                ) : isUninstalling ? (
                  <Button isLoading loadingText="Uninstalling" colorScheme="green" />
                ) : (
                  <Button
                    disabled={isCreatingInstallation}
                    isLoading={isBusy || !isEndpointAvailable}
                    loadingText={getLoadingText()}
                    colorScheme="green"
                    onClick={onClickOpenDatabase}
                  >
                    Open database UI
                  </Button>
                )
              }
            />
          )}
          <UninstallExampleAlertDialog
            example={example}
            installationId={installation?.id || ""}
            isOpen={isUninstallAlertDialogOpen}
            onClose={onCloseUninstallAlertDialog}
          />
        </ButtonGroup>
      </CardFooter>
    </Card>
  );
};

export default ExampleRowList;
