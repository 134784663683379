//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Divider, Form, Grid, InputOnChangeData, Modal } from "semantic-ui-react";
import {
  BackupList as ApiBackupList,
  BackupPolicyList as ApiBackupPolicyList,
  Deployment as ApiDeployment,
  Organization as ApiOrganization,
  Project as ApiProject,
  TermsAndConditions as ApiTermsAndConditions,
  Organization,
} from "../../api/lib";
import {
  ContentActionButtonBackup,
  Footnote,
  NumberInput,
  PagingButtons,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { BackupListView } from "../backup/BackupList";
import BackupPolicyList from "../backup/BackupPolicyList";
import { BackupRestoreTableView } from "../backup/BackupRestore";
import { BackupSummaryTableView } from "../backup/BackupSummary";
import { DeploymentPausedModal } from "../pause/DeploymentPausedModal";
import RedirectToBillingPopover from "../pricing/RedirectToBillingPopover";
import { FreeTierID } from "../../constants";

// 20 years in days, used as a sensible maximum value for auto deleting uploads.
const twentyYearsInDays = 7300;

interface IBackupsViewArgs extends IWithRefreshProps, RouteComponentProps, ICreateBackupViewArgs, IEditBackupViewArgs {
  organization: ApiOrganization;
  project: ApiProject;

  deployment: ApiDeployment;
  backups?: ApiBackupList;
  lastGoodBackups?: ApiBackupList;
  backupPolicies?: ApiBackupPolicyList;

  currentTC?: ApiTermsAndConditions;
  acceptedTC?: string;
  showAcceptTermsAndConditions: boolean;
  onClickCancelShowAcceptTermsAndConditions: () => void;
  onClickTriggerShowAcceptTermsAndConditions: () => void;
  requiresAcceptanceOfTermsAndConditions: () => boolean;

  onClickCreateBackup: () => void;
  onClickBackupRestore: (id: string) => void;
  onClickBackupEdit: (id: string) => void;
  onClickBackupDownload: (id: string) => void;
  onClickBackupDelete: (id: string) => void;
  onClickCloneDeploymentFromBackup: (id: string, needsNewTC: boolean) => void;
  onResumeDeployment: () => void;

  page: number;
  pageSize: number;
  onNextPage: () => void;
  onPreviousPage: () => void;

  isMultiRegionBackupEnabled?: boolean;
}

export const BackupsView = ({ ...args }: IBackupsViewArgs) => {
  const isListPoliciesAllowed = args.hasPermissionByUrl && args.hasPermissionByUrl(args.deployment.url || "", ResourceType.Backup, "backup.backuppolicy.list");
  const isCreateAllowed = args.hasPermissionByUrl && args.hasPermissionByUrl(args.deployment.url || "", ResourceType.Deployment, "backup.backup.create");
  const count = args.backups && args.backups.items ? args.backups.items.length : 0;

  return (
    <div>
      <Section>
        <div>
          <Section>
            <SectionHead>
              <SectionHeader title="Summary" />
            </SectionHead>
            <SectionContent>
              <BackupSummaryTableView
                {...args}
                isDeploymentPaused={!!args.deployment.is_paused}
                totalBackupSizeBytes={args.deployment.status ? args.deployment.status.total_backup_size_bytes || 0 : 0}
                backupUploadInProgress={(args.deployment.status && args.deployment.status.backup_upload_in_progress) || false}
              />
            </SectionContent>
          </Section>
          {isListPoliciesAllowed && <BackupPolicyList {...args} loading={false} />}
          <Section>
            <SectionHead>
              <SectionHeader title="Backups" children={<PagingButtons {...args} count={count} size="tiny" />} />
              <SectionButtons>
                {isCreateAllowed && <DeploymentPausedModal {...args} onClick={args.onClickCreateBackup} trigger={<ContentActionButtonBackup primary />} />}
              </SectionButtons>
            </SectionHead>
            <SectionContent>
              <BackupListView {...args} />
            </SectionContent>
          </Section>
          <BackupRestoreTableView {...args} />
          <CreateBackupView {...args} />
          <EditBackupView {...args} />
        </div>
      </Section>
    </div>
  );
};

export interface ICreateBackupViewArgs extends IBackupViewArgs {
  createBackup: boolean;
  onClickCancelCreateBackup: () => void;
  onClickSaveCreateBackup: () => void;
}

export const CreateBackupView = ({ ...args }: ICreateBackupViewArgs) => {
  const has_name = !_.isEmpty(args.name);
  return (
    <Modal size="large" open={!!args.createBackup} onClose={args.onClickCancelCreateBackup}>
      <Modal.Header>New Backup</Modal.Header>
      <Modal.Content>
        <BackupView {...args} />
      </Modal.Content>
      <Modal.Actions>
        <Button primary icon="save" labelPosition="right" content="Start backing up" disabled={!has_name} onClick={args.onClickSaveCreateBackup} />
        <Button onClick={args.onClickCancelCreateBackup}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

export interface IEditBackupViewArgs extends IBackupViewArgs {
  editBackup: boolean;
  onClickCancelEditBackup: () => void;
  onClickSaveEditBackup: () => void;
}

export const EditBackupView = ({ ...args }: IEditBackupViewArgs) => {
  const has_name = !_.isEmpty(args.name);
  return (
    <Modal size="large" open={!!args.editBackup} onClose={args.onClickCancelEditBackup}>
      <Modal.Header>Edit Backup</Modal.Header>
      <Modal.Content>
        <BackupView {...args} />
      </Modal.Content>
      <Modal.Actions>
        <Button primary icon="save" labelPosition="right" content="Save" disabled={!has_name} onClick={args.onClickSaveEditBackup} />
        <Button onClick={args.onClickCancelEditBackup}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

export interface IBackupViewArgs {
  name: string;
  updateName: (newValue: string) => void;
  description: string;
  updateDescription: (newValue: string) => void;
  autoDeleteUpload: boolean;
  toggleAutoDeleteUpload: () => void;
  autoDeleteUploadInDays: number;
  updateAutoDeleteUploadInDays: (newValue: number) => void;
  autoDeleteNoUploadInHours: number;
  updateAutoDeleteNoUploadInHours: (newValue: number) => void;
  isBackupUploadFeatureAvailable: boolean;
  uploadBackup: boolean;
  toggleUploadBackup: () => void;
  organization: Organization;
  isMultiRegionBackupEnabled?: boolean;
}

const BackupView = ({ ...args }: IBackupViewArgs) => (
  <Form>
    <Section>
      <SectionHead>
        <SectionHeader title="General" />
      </SectionHead>
      <SectionContent>
        <Grid>
          <Grid.Row columns={16}>
            <Grid.Column width={6}>
              <Form.Input
                autoFocus
                required
                label="Name"
                placeholder='Name (e.g. "before upgrade")'
                name="name"
                value={args.name}
                onChange={(e: any, id: InputOnChangeData) => {
                  args.updateName(id.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.Input
                label="Short description"
                placeholder="Description"
                name="description"
                value={args.description}
                onChange={(e: any, id: InputOnChangeData) => {
                  args.updateDescription(id.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionContent>
    </Section>
    <Section>
      <SectionHead>
        <SectionHeader title="Options" />
      </SectionHead>
      <SectionContent>
        <Grid>
          <Grid.Row columns={16}>
            <Grid.Column width={8}>
              <RedirectToBillingPopover
                organizationId={args.organization.id || ""}
                disabled={!((args.organization.tier && args.organization.tier.id) === FreeTierID)}
              >
                <Form.Checkbox
                  disabled={!args.isBackupUploadFeatureAvailable}
                  toggle
                  label="Upload backup to cloud storage"
                  onChange={args.toggleUploadBackup}
                  checked={args.uploadBackup}
                />
              </RedirectToBillingPopover>

              <Footnote style={{ marginTop: "1rem" }}>
                When a backup is uploaded to cloud storage it is preserved for a very long time and does not occupy any disk space on the servers.
                {args.isMultiRegionBackupEnabled && (
                  <>
                    <b>This will also allow copying the backup to different regions.</b>
                    <Divider hidden />
                  </>
                )}
              </Footnote>
            </Grid.Column>
            {args.uploadBackup && (
              <Grid.Column width={8}>
                <Form.Checkbox toggle label="Auto delete" onChange={args.toggleAutoDeleteUpload} checked={args.autoDeleteUpload} />
                <NumberInput
                  disabled={!args.autoDeleteUpload}
                  value={args.autoDeleteUploadInDays}
                  min={1}
                  max={twentyYearsInDays}
                  ext=" days"
                  onChange={args.updateAutoDeleteUploadInDays}
                />
              </Grid.Column>
            )}
            {!args.uploadBackup && (
              <Grid.Column width={8}>
                <Form.Checkbox toggle label="Auto delete" checked={true} disabled={true} />
                <NumberInput value={args.autoDeleteNoUploadInHours} min={1} max={6} ext=" hours" onChange={args.updateAutoDeleteNoUploadInHours} />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </SectionContent>
    </Section>
  </Form>
);
