//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Icon, Loader, Popup, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { daysToDuration, Duration, durationAsDays, durationAsHours, hoursToDuration } from "../../api/googleTypes";
import {
  BackupPolicy as ApiBackupPolicy,
  BackupPolicyList as ApiBackupPolicyList,
  BackupPolicy_DailySchedule as ApiBackupPolicy_DailySchedule,
  BackupPolicy_HourlySchedule as ApiBackupPolicy_HourlySchedule,
  BackupPolicy_MonthlySchedule as ApiBackupPolicy_MonthlySchedule,
  BackupPolicy_Schedule as ApiBackupPolicy_Schedule,
  Deployment as ApiDeployment,
  IDOptions as ApiIDOptions,
  ListBackupPoliciesRequest as ApiListBackupPoliciesRequest,
  ListRegionsRequest,
  Organization as ApiOrganization,
  Project as ApiProject,
  TimeOfDay as ApiTimeOfDay,
} from "../../api/lib";
import { reportError } from "../../errors/reporting";
import {
  Confirm,
  ConfirmInfo,
  ContentActionButtonNew,
  ErrorMessage,
  humanizeDuration,
  ListActionDelete,
  ListActionEdit,
  LoaderBoxForTable as LoaderBox,
  Loading,
  Processing,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
  Locked,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { DeploymentPausedModal } from "../pause/DeploymentPausedModal";
import {
  BackupNotification,
  BackupNotificationType,
  BackupSchedule,
  BackupScheduleType,
  CreateBackupPolicyView,
  EditBackupPolicyView,
} from "./BackupPolicyViews";
import { useWithRefresh } from "../../util/WithRefreshContext";

// Interface describing a backup policy
interface IHeaderView {
  loading: boolean;
  isMultiRegionBackupEnabled?: boolean;
}

const HeaderView = ({ loading, isMultiRegionBackupEnabled }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      {isMultiRegionBackupEnabled && (
        <Table.HeaderCell>
          Additional Regions <Popup trigger={<Icon name="info circle" color="grey" />} content="The destination regions where the default backup is copied." />{" "}
        </Table.HeaderCell>
      )}
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Schedule</Table.HeaderCell>
      <Table.HeaderCell>Is Paused</Table.HeaderCell>
      <Table.HeaderCell>Upload</Table.HeaderCell>
      <Table.HeaderCell>Retention period</Table.HeaderCell>
      <Table.HeaderCell>Email notification</Table.HeaderCell>
      <Table.HeaderCell>Next backup</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface ISchedule {
  schedule: ApiBackupPolicy_Schedule;
}

const Schedule = ({ ...args }: ISchedule) => {
  switch (args.schedule.schedule_type) {
    case "Hourly":
      return (
        <span>
          {args.schedule.schedule_type} every {args.schedule.hourly_schedule && args.schedule.hourly_schedule.schedule_every_interval_hours} hour(s){" "}
          {args.schedule.hourly_schedule && !!args.schedule.hourly_schedule.minutes_offset
            ? `at ${args.schedule.hourly_schedule.minutes_offset} minute(s)`
            : ""}
        </span>
      );
    case "Daily":
      let days = "";
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.monday) {
        days += "Mon ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.tuesday) {
        days += "Tue ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.wednesday) {
        days += "Wed ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.thursday) {
        days += "Thu ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.friday) {
        days += "Fri ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.saturday) {
        days += "Sat ";
      }
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.sunday) {
        days += "Sun ";
      }
      let at_daily = "";
      if (args.schedule.daily_schedule && args.schedule.daily_schedule.schedule_at) {
        at_daily = `${args.schedule.daily_schedule.schedule_at.hours || "0"}:${args.schedule.daily_schedule.schedule_at.minutes || "00"}h ${
          args.schedule.daily_schedule.schedule_at.time_zone
        }`;
      }
      return (
        <span>
          {args.schedule.schedule_type} on {days}at {at_daily}
        </span>
      );
    case "Monthly":
      let at_montly = "";
      if (args.schedule.monthly_schedule && args.schedule.monthly_schedule.schedule_at) {
        at_montly = `${args.schedule.monthly_schedule.schedule_at.hours || "0"}:${args.schedule.monthly_schedule.schedule_at.minutes || "00"}h ${
          args.schedule.monthly_schedule.schedule_at.time_zone
        }`;
      }
      return (
        <span>
          {args.schedule.schedule_type} day {args.schedule.monthly_schedule && args.schedule.monthly_schedule.day_of_month} at {at_montly}
        </span>
      );
  }

  return <span>-</span>;
};

// Interface describing a backup policy
interface IRowView {
  isDeploymentPaused: boolean;
  active: boolean;
  item: ApiBackupPolicy;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onResumeDeployment: () => void;
  isMultiRegionBackupEnabled?: boolean;
}

const RowView = ({ ...args }: IRowView) => {
  const locked = !!args.item.locked;
  const { hasPermissionByUrl } = useWithRefresh();
  const updateAction = !args.item.is_deleted && hasPermissionByUrl?.(args.item.url || "", ResourceType.BackupPolicy, "backup.backuppolicy.update");
  const deleteAction = !args.item.is_deleted && hasPermissionByUrl?.(args.item.url || "", ResourceType.BackupPolicy, "backup.backuppolicy.delete");
  let nextBackupText = args.item.status && args.item.status.next_backup ? moment(args.item.status.next_backup).fromNow() : "-";
  if (args.isDeploymentPaused) {
    nextBackupText = "Deployment Paused";
  }
  const email_notification = args.item.email_notification ? BackupNotification.fromAPI(args.item.email_notification) : undefined;
  const [regionNames, setRegionNames] = useState<(string | undefined)[]>([]);

  const getRegionNames = async (regionIDs: string[]) => {
    if (!args.isMultiRegionBackupEnabled) return "-";
    try {
      const provider = useDeploymentStore.getState().region;
      const organization = useGlobalStore.getState().organization;
      const listReq: ListRegionsRequest = { provider_id: provider.provider_id, organization_id: organization.id };
      const regions = await apiClients.platformClient.ListRegions(listReq);
      const availableRegions = regions && regions.items ? regions.items.filter((x) => x.available) : [];
      const regionNames = availableRegions.filter((region) => regionIDs.includes(region.id || ""));
      setRegionNames(regionNames.map((region) => region.location));
    } catch (err) {
      return regionIDs;
    }
  };

  useEffect(() => {
    if (args.isMultiRegionBackupEnabled) {
      args.item.additional_region_ids && args.item.additional_region_ids.length && getRegionNames(args.item.additional_region_ids);
    }
  }, [args.item]);

  return (
    <Table.Row>
      <Table.Cell>
        {args.item.name}
        {locked && <Locked />}
      </Table.Cell>
      {args.isMultiRegionBackupEnabled && (
        <Table.Cell>
          {args.item.additional_region_ids && args.item.additional_region_ids.length ? (
            <>
              {regionNames[0]}
              <Popup
                trigger={<b>{regionNames.length > 1 && ` + ${regionNames.length - 1} more`}</b>}
                content={
                  <div>
                    {regionNames.slice(1).map((region) => (
                      <div>{region}</div>
                    ))}
                  </div>
                }
              />
            </>
          ) : (
            "-"
          )}
        </Table.Cell>
      )}
      <Table.Cell>{args.item.description}</Table.Cell>
      <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
      <Table.Cell>{args.item.schedule ? <Schedule schedule={args.item.schedule} /> : "-"}</Table.Cell>
      <Table.Cell>{args.item.is_paused ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{args.item.upload ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{args.item.retention_period ? humanizeDuration(args.item.retention_period) : "-"}</Table.Cell>
      <Table.Cell>{email_notification ? BackupNotification.toText(email_notification) : "-"}</Table.Cell>
      <Table.Cell>{nextBackupText}</Table.Cell>
      <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          {updateAction && <DeploymentPausedModal {...args} onClick={args.onClickEdit} trigger={<ListActionEdit disabled={!args.active} />} />}
          {deleteAction && <DeploymentPausedModal {...args} onClick={args.onClickDelete} trigger={<ListActionDelete disabled={!args.active || locked} />} />}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the backup policy
interface IListView {
  items: ApiBackupPolicy[];
  isDeploymentPaused: boolean;
  active: boolean;
  loading: boolean;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
  onResumeDeployment: () => void;
  isMultiRegionBackupEnabled?: boolean;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView loading={args.loading} isMultiRegionBackupEnabled={args.isMultiRegionBackupEnabled} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickEdit={() => args.onClickEdit(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No backup policies are configured for this deployment</div>;

// Interface describing the backup policy list view arguments
export interface IBackupPolicyListViewArgs extends RouteComponentProps {
  active: boolean;
  loading: boolean;
  backupPolicies?: ApiBackupPolicyList;
  isDeploymentPaused: boolean;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
  onResumeDeployment: () => void;
  isMultiRegionBackupEnabled?: boolean;
}

export const BackupPolicyListView = ({ ...args }: IBackupPolicyListViewArgs) => {
  if (!args.backupPolicies) {
    return <Loading />;
  }
  if (!args.backupPolicies.items || args.backupPolicies.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.backupPolicies.items} />;
};

// Interface decribing the properties of the backup policy list component
interface IBackupPolicyListProps extends IWithRefreshProps, RouteComponentProps {
  organization: ApiOrganization;
  project: ApiProject;
  isMultiRegionBackupEnabled?: boolean;
  onResumeDeployment: () => void;
}

// Interface decribing the state of the backup policy list component
interface IBackupPolicyListState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  deploymentId?: string;
  deployment?: ApiDeployment;
  backupPolicies?: ApiBackupPolicyList;

  createBackupPolicy: boolean;
  editBackupPolicy: boolean;
  editBackupPolicyId?: string;
  name: string;
  description: string;
  is_paused: boolean;
  isBackupUploadFeatureAvailable: boolean;
  upload: boolean;
  hasRetentionPeriodUpload: boolean;
  retentionPeriodUploadInDays: number;
  retentionPeriodNoUploadInHours: number;
  email_notification: BackupNotificationType;
  schedule_type: BackupScheduleType;
  hourly_schedule?: ApiBackupPolicy_HourlySchedule;
  daily_schedule?: ApiBackupPolicy_DailySchedule;
  monthly_schedule?: ApiBackupPolicy_MonthlySchedule;
  locked: boolean;

  additionalRegions: string[];
  checkedMultipleRegionBackup: boolean;
}

// The component to show the backup policies inside a deployment as a list.
class BackupPolicyList extends Component<IBackupPolicyListProps, IBackupPolicyListState> {
  state = {
    errorMessage: undefined,
    processing: false,
    confirmInfo: undefined,
    groups: undefined,

    createBackupPolicy: false,
    editBackupPolicy: false,
    editBackupPolicyId: undefined,
    name: "",
    description: "",
    is_paused: false,
    isBackupUploadFeatureAvailable: false,
    upload: true,
    hasRetentionPeriodUpload: true,
    retentionPeriodUploadInDays: 31,
    retentionPeriodNoUploadInHours: 6,
    email_notification: BackupNotificationType.FailureOnly,
    schedule_type: 0 as BackupScheduleType,
    hourly_schedule: undefined,
    daily_schedule: undefined,
    monthly_schedule: undefined,
    locked: false,
    additionalRegions: [],
    checkedMultipleRegionBackup: false,
  } as IBackupPolicyListState;

  reloadBackupPolicyInfo = async () => {
    const idOptions = { id: this.state.deploymentId } as ApiIDOptions;
    const deployment = await apiClients.dataClient.GetDeployment(idOptions);
    if (!this.state.deployment) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadBackupPolicyInfo, `${deployment.url}/BackupPolicy/*`);
    }
    this.setState({ deployment: deployment });
    const isBackupUploadFeatureAvailable = await apiClients.backupClient.IsBackupUploadFeatureAvailable(idOptions);
    this.setState({ isBackupUploadFeatureAvailable: !!isBackupUploadFeatureAvailable.result });
    const req = { deployment_id: deployment.id } as ApiListBackupPoliciesRequest;
    const backupPolicies = await apiClients.backupClient.ListBackupPolicies(req);
    this.setState({ backupPolicies: backupPolicies });
  };

  refreshBackupPolicyInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadBackupPolicyInfo);
  };

  getBackupPolicyName = (id: string) => {
    const backupPolicies = this.state.backupPolicies;
    if (backupPolicies && backupPolicies.items) {
      const backupPolicy = backupPolicies.items.find((bp) => bp.id == id);
      if (backupPolicy) {
        return backupPolicy.name;
      }
    }
    return "";
  };

  getDefaultHourlySchedule = (): ApiBackupPolicy_HourlySchedule => {
    const hourly_schedule = {
      schedule_every_interval_hours: 1,
    } as ApiBackupPolicy_HourlySchedule;
    return hourly_schedule;
  };

  getDefaultDailySchedule = (): ApiBackupPolicy_DailySchedule => {
    const daily_schedule = {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
      schedule_at: {
        hours: 0,
        minutes: 0,
        time_zone: "UTC",
      } as ApiTimeOfDay,
    } as ApiBackupPolicy_DailySchedule;
    return daily_schedule;
  };

  getDefaultMonthlySchedule = (): ApiBackupPolicy_MonthlySchedule => {
    const monthly_schedule = {
      day_of_month: 1,
      schedule_at: {
        hours: 0,
        minutes: 0,
        time_zone: "UTC",
      } as ApiTimeOfDay,
    } as ApiBackupPolicy_MonthlySchedule;
    return monthly_schedule;
  };

  onClickCreateBackupPolicy = () => {
    this.setState({
      createBackupPolicy: true,
      name: "",
      description: "",
      hasRetentionPeriodUpload: true,
      retentionPeriodUploadInDays: 31,
      retentionPeriodNoUploadInHours: 6,
      upload: this.state.isBackupUploadFeatureAvailable,
      is_paused: false,
      email_notification: BackupNotificationType.FailureOnly,
      schedule_type: 0 as BackupScheduleType,
      hourly_schedule: this.getDefaultHourlySchedule(),
      daily_schedule: this.getDefaultDailySchedule(),
      monthly_schedule: this.getDefaultMonthlySchedule(),
      locked: false,
      additionalRegions: [],
      checkedMultipleRegionBackup: false,
    });
  };

  disableMultiRegionToggle = () => {
    this.setState({ checkedMultipleRegionBackup: false });
  };

  onClickCancelCreateBackupPolicy = () => {
    this.setState({ createBackupPolicy: false });
    const { additionalRegions } = this.state;
    if (this.props.isMultiRegionBackupEnabled && !additionalRegions.length) {
      this.disableMultiRegionToggle();
    }
  };

  onClickCancelEditBackupPolicy = () => {
    this.setState({ editBackupPolicy: false });
    const { additionalRegions } = this.state;
    if (this.props.isMultiRegionBackupEnabled && !additionalRegions.length) {
      this.disableMultiRegionToggle();
    }
  };

  updateName = (newValue: string) => {
    this.setState({ name: newValue });
  };

  updateDescription = (newValue: string) => {
    this.setState({ description: newValue });
  };

  toggleHasRetentionPeriodUpload = () => {
    this.setState((state: IBackupPolicyListState) => {
      return { hasRetentionPeriodUpload: !state.hasRetentionPeriodUpload };
    });
  };

  updateRetentionPeriodUploadInDays = (newValue: number) => {
    this.setState({ retentionPeriodUploadInDays: newValue });
  };

  updateRetentionPeriodNoUploadInHours = (newValue: number) => {
    this.setState({ retentionPeriodNoUploadInHours: newValue });
  };

  toggleUpload = () => {
    const { additionalRegions, upload: previousUploadState } = this.state;
    if (this.props.isMultiRegionBackupEnabled && additionalRegions.length && previousUploadState) {
      // Cloud backup disabled post adding multiple regions.
      // Show confirmation if the user is okay to do it
      const confirmInfo = {
        header: "Disable cloud storage?",
        content: `Disabling cloud storage will no longer allow backups to be created across multiple regions. The backup(s) which are already created will not be affected.`,
        warning: "Are you sure you want to proceed?",
        invertPositiveNegative: true,
        onConfirm: () => {
          this.setState({ additionalRegions: [], upload: !previousUploadState, confirmInfo: undefined, checkedMultipleRegionBackup: false });
        },
        onDenied: () => {
          this.setState({ confirmInfo: undefined });
        },
      } as ConfirmInfo;
      this.setState({ confirmInfo: confirmInfo });
    } else {
      this.setState((state: IBackupPolicyListState) => {
        if (this.props.isMultiRegionBackupEnabled) this.setState({ checkedMultipleRegionBackup: false });
        return { upload: !state.upload };
      });
    }
  };

  toggleIsPaused = () => {
    this.setState((state: IBackupPolicyListState) => {
      return { is_paused: !state.is_paused };
    });
  };

  toggleLocked = () => {
    this.setState((state: IBackupPolicyListState) => {
      return { locked: !state.locked };
    });
  };

  updateEmailNotification = (newValue: BackupNotificationType) => {
    this.setState({ email_notification: newValue });
  };

  updateScheduleType = (newValue: BackupScheduleType) => {
    this.setState({ schedule_type: newValue });
  };

  updateHourlyIntervalMinutes = (newValue: number) => {
    if (this.state.hourly_schedule) {
      const schedule = { ...this.state.hourly_schedule };
      schedule.minutes_offset = newValue;
      this.setState({ hourly_schedule: schedule });
    }
  };

  updateHourlyIntervalHours = (newValue: number) => {
    if (this.state.hourly_schedule) {
      this.setState({
        hourly_schedule: {
          ...this.state.hourly_schedule,
          schedule_every_interval_hours: newValue,
        },
      });
    }
  };

  toggleDailyDay = (day: string) => {
    this.setState((state: IBackupPolicyListState) => {
      if (state.daily_schedule) {
        switch (day) {
          case "Monday":
            state.daily_schedule.monday = !state.daily_schedule.monday;
            break;
          case "Tuesday":
            state.daily_schedule.tuesday = !state.daily_schedule.tuesday;
            break;
          case "Wednesday":
            state.daily_schedule.wednesday = !state.daily_schedule.wednesday;
            break;
          case "Thursday":
            state.daily_schedule.thursday = !state.daily_schedule.thursday;
            break;
          case "Friday":
            state.daily_schedule.friday = !state.daily_schedule.friday;
            break;
          case "Saturday":
            state.daily_schedule.saturday = !state.daily_schedule.saturday;
            break;
          case "Sunday":
            state.daily_schedule.sunday = !state.daily_schedule.sunday;
            break;
        }
      }
      return { daily_schedule: state.daily_schedule };
    });
  };

  updateDailyTimeHours = (newValue: number) => {
    if (this.state.daily_schedule && this.state.daily_schedule.schedule_at) {
      this.setState({
        daily_schedule: {
          ...this.state.daily_schedule,
          schedule_at: {
            ...this.state.daily_schedule.schedule_at,
            hours: newValue,
          },
        },
      });
    }
  };

  updateDailyTimeMinutes = (newValue: number) => {
    if (this.state.daily_schedule && this.state.daily_schedule.schedule_at) {
      this.setState({
        daily_schedule: {
          ...this.state.daily_schedule,
          schedule_at: {
            ...this.state.daily_schedule.schedule_at,
            minutes: newValue,
          },
        },
      });
    }
  };

  updateMonthlyDay = (newValue: number) => {
    if (this.state.monthly_schedule) {
      this.setState({
        monthly_schedule: {
          ...this.state.monthly_schedule,
          day_of_month: newValue,
        },
      });
    }
  };

  updateMonthlyTimeHours = (newValue: number) => {
    if (this.state.monthly_schedule && this.state.monthly_schedule.schedule_at) {
      this.setState({
        monthly_schedule: {
          ...this.state.monthly_schedule,
          schedule_at: {
            ...this.state.monthly_schedule.schedule_at,
            hours: newValue,
          },
        },
      });
    }
  };

  updateMonthlyTimeMinutes = (newValue: number) => {
    if (this.state.monthly_schedule && this.state.monthly_schedule.schedule_at) {
      this.setState({
        monthly_schedule: {
          ...this.state.monthly_schedule,
          schedule_at: {
            ...this.state.monthly_schedule.schedule_at,
            minutes: newValue,
          },
        },
      });
    }
  };

  getBackupScheduleFromState(): ApiBackupPolicy_Schedule {
    switch (this.state.schedule_type) {
      case BackupScheduleType.Hourly:
        return {
          schedule_type: "Hourly",
          hourly_schedule: this.state.hourly_schedule,
          daily_schedule: undefined,
          monthly_schedule: undefined,
        } as ApiBackupPolicy_Schedule;
      case BackupScheduleType.Daily:
        return {
          schedule_type: "Daily",
          hourly_schedule: undefined,
          daily_schedule: this.state.daily_schedule,
          monthly_schedule: undefined,
        } as ApiBackupPolicy_Schedule;
      case BackupScheduleType.Monthly:
        return {
          schedule_type: "Monthly",
          hourly_schedule: undefined,
          daily_schedule: undefined,
          monthly_schedule: this.state.monthly_schedule,
        } as ApiBackupPolicy_Schedule;
      default:
        throw "Please select schedule type";
    }
  }

  getBackupRetentionPeriodFromState(): Duration | undefined {
    let retentionPeriod: Duration | undefined = undefined;
    if (this.state.upload) {
      if (this.state.hasRetentionPeriodUpload) {
        retentionPeriod = daysToDuration(this.state.retentionPeriodUploadInDays);
      }
    } else {
      retentionPeriod = hoursToDuration(this.state.retentionPeriodNoUploadInHours);
    }
    return retentionPeriod;
  }

  getBackupPolicyFromState(): ApiBackupPolicy {
    const backupPolicy = {
      id: this.state.editBackupPolicyId,
      deployment_id: this.state.deploymentId || "",
      name: this.state.name,
      description: this.state.description,
      retention_period: this.getBackupRetentionPeriodFromState(),
      upload: this.state.upload,
      is_paused: this.state.is_paused,
      email_notification: BackupNotification.toAPI(this.state.email_notification),
      schedule: this.getBackupScheduleFromState(),
      locked: this.state.locked,
      additional_region_ids: this.state.upload ? this.state.additionalRegions : [],
    } as ApiBackupPolicy;
    return backupPolicy;
  }

  onClickSaveCreateBackupPolicy = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const backupPolicy = this.getBackupPolicyFromState();
      await apiClients.backupClient.CreateBackupPolicy(backupPolicy);
      this.refreshBackupPolicyInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup policy creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false, createBackupPolicy: false });
  };

  onClickEditBackupPolicy = (id: string) => {
    const backupPolicies = this.state.backupPolicies;
    if (backupPolicies && backupPolicies.items) {
      const backupPolicy = backupPolicies.items.find((bp) => bp.id == id);
      if (backupPolicy) {
        const hasRetentionPeriodUpload = backupPolicy.upload ? !!backupPolicy.retention_period : false;
        this.setState({
          editBackupPolicy: true,
          editBackupPolicyId: id,
          name: backupPolicy.name || "",
          description: backupPolicy.description || "",
          hasRetentionPeriodUpload: hasRetentionPeriodUpload,
          retentionPeriodUploadInDays: hasRetentionPeriodUpload ? durationAsDays(backupPolicy.retention_period || 0) : 31,
          retentionPeriodNoUploadInHours: !backupPolicy.upload ? durationAsHours(backupPolicy.retention_period || 0) : 6,
          upload: !!backupPolicy.upload,
          is_paused: !!backupPolicy.is_paused,
          email_notification: BackupNotification.fromAPI(backupPolicy.email_notification || ""),
          schedule_type: BackupSchedule.toType((backupPolicy.schedule && backupPolicy.schedule.schedule_type) || ""),
          hourly_schedule: (backupPolicy.schedule && _.cloneDeep(backupPolicy.schedule.hourly_schedule)) || this.getDefaultHourlySchedule(),
          daily_schedule: (backupPolicy.schedule && _.cloneDeep(backupPolicy.schedule.daily_schedule)) || this.getDefaultDailySchedule(),
          monthly_schedule: (backupPolicy.schedule && _.cloneDeep(backupPolicy.schedule.monthly_schedule)) || this.getDefaultMonthlySchedule(),
          locked: !!backupPolicy.locked,
          additionalRegions: backupPolicy.additional_region_ids || [],
        });
      }
    }
  };

  onClickSaveEditBackupPolicy = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const backupPolicy = this.getBackupPolicyFromState();
      await apiClients.backupClient.UpdateBackupPolicy(backupPolicy);
      this.refreshBackupPolicyInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false, editBackupPolicy: false });
  };

  onClickDeleteBackupPolicy = async (id: string) => {
    const backupPolicyName = this.getBackupPolicyName(id);
    const confirmInfo = {
      header: "Delete Backup policy",
      content: `Are you sure you want to delete backup policy '${backupPolicyName}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteBackupPolicyConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteBackupPolicyConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.backupClient.DeleteBackupPolicy(idOptions);
      this.refreshBackupPolicyInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup policy deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  componentDidMount() {
    const deploymentId = (this.props.match.params as any).deploymentId;
    this.setState({ deploymentId: deploymentId }, () => {
      //notice inside the reloadBackupPolicyInfo (called by refreshBackupPolicyInfo) we use the deploymentId
      this.refreshBackupPolicyInfo();
    });
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onMultiRegionSelection = (data: string[]) => {
    this.setState({ additionalRegions: data });
  };

  toggleRegionDropdown = (enabled: boolean) => {
    this.setState({ checkedMultipleRegionBackup: enabled }, () => {
      if (!enabled) {
        this.setState({ additionalRegions: [] });
      }
    });
  };

  areRegionsValid = () => {
    if (this.props.isMultiRegionBackupEnabled && this.state.checkedMultipleRegionBackup && !this.state.additionalRegions.length) {
      return false;
    }

    return true;
  };

  render() {
    const hasPermissionByUrl = this.props.hasPermissionByUrl;
    const has_deployment = !!this.state.deployment;
    const deployment = this.state.deployment || {};
    const isCreatePolicyAllowed =
      has_deployment && hasPermissionByUrl && hasPermissionByUrl(deployment.url || "", ResourceType.BackupPolicy, "backup.backuppolicy.create");

    return (
      <Section>
        <SectionHead>
          <SectionHeader title="Policies" />
          <SectionButtons>
            {isCreatePolicyAllowed && (
              <DeploymentPausedModal
                {...this.props}
                onClick={this.onClickCreateBackupPolicy}
                trigger={<ContentActionButtonNew primary content="New backup policy" disabled={this.state.processing} />}
              />
            )}
          </SectionButtons>
        </SectionHead>
        <SectionContent>
          <Confirm confirmInfo={this.state.confirmInfo} />
          <Processing active={this.state.processing} message="Processing, please wait..." />
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <div>
            <BackupPolicyListView
              {...this.props}
              isDeploymentPaused={deployment.is_paused || false}
              active={!this.state.processing}
              backupPolicies={this.state.backupPolicies}
              onClickEdit={this.onClickEditBackupPolicy}
              onClickDelete={this.onClickDeleteBackupPolicy}
            />
            <CreateBackupPolicyView
              {...this.props}
              {...this.state}
              hourlyIntervalHours={(this.state.hourly_schedule && this.state.hourly_schedule.schedule_every_interval_hours) || 0}
              hourlyIntervalMinutes={(this.state.hourly_schedule && this.state.hourly_schedule.minutes_offset) || Math.floor(Math.random() * 60)}
              dailyMonday={(this.state.daily_schedule && this.state.daily_schedule.monday) || false}
              dailyTuesday={(this.state.daily_schedule && this.state.daily_schedule.tuesday) || false}
              dailyWednesday={(this.state.daily_schedule && this.state.daily_schedule.wednesday) || false}
              dailyThursday={(this.state.daily_schedule && this.state.daily_schedule.thursday) || false}
              dailyFriday={(this.state.daily_schedule && this.state.daily_schedule.friday) || false}
              dailySaturday={(this.state.daily_schedule && this.state.daily_schedule.saturday) || false}
              dailySunday={(this.state.daily_schedule && this.state.daily_schedule.sunday) || false}
              dailyTimeHours={(this.state.daily_schedule && this.state.daily_schedule.schedule_at && this.state.daily_schedule.schedule_at.hours) || 0}
              dailyTimeMinutes={(this.state.daily_schedule && this.state.daily_schedule.schedule_at && this.state.daily_schedule.schedule_at.minutes) || 0}
              monthlyDay={(this.state.monthly_schedule && this.state.monthly_schedule.day_of_month) || 0}
              monthlyTimeHours={(this.state.monthly_schedule && this.state.monthly_schedule.schedule_at && this.state.monthly_schedule.schedule_at.hours) || 0}
              monthlyTimeMinutes={
                (this.state.monthly_schedule && this.state.monthly_schedule.schedule_at && this.state.monthly_schedule.schedule_at.minutes) || 0
              }
              onLockedChanged={this.toggleLocked}
              {...this}
            />
            <EditBackupPolicyView
              {...this.props}
              {...this.state}
              hourlyIntervalHours={(this.state.hourly_schedule && this.state.hourly_schedule.schedule_every_interval_hours) || 0}
              hourlyIntervalMinutes={(this.state.hourly_schedule && this.state.hourly_schedule.minutes_offset) || 0}
              dailyMonday={(this.state.daily_schedule && this.state.daily_schedule.monday) || false}
              dailyTuesday={(this.state.daily_schedule && this.state.daily_schedule.tuesday) || false}
              dailyWednesday={(this.state.daily_schedule && this.state.daily_schedule.wednesday) || false}
              dailyThursday={(this.state.daily_schedule && this.state.daily_schedule.thursday) || false}
              dailyFriday={(this.state.daily_schedule && this.state.daily_schedule.friday) || false}
              dailySaturday={(this.state.daily_schedule && this.state.daily_schedule.saturday) || false}
              dailySunday={(this.state.daily_schedule && this.state.daily_schedule.sunday) || false}
              dailyTimeHours={(this.state.daily_schedule && this.state.daily_schedule.schedule_at && this.state.daily_schedule.schedule_at.hours) || 0}
              dailyTimeMinutes={(this.state.daily_schedule && this.state.daily_schedule.schedule_at && this.state.daily_schedule.schedule_at.minutes) || 0}
              monthlyDay={(this.state.monthly_schedule && this.state.monthly_schedule.day_of_month) || 0}
              monthlyTimeHours={(this.state.monthly_schedule && this.state.monthly_schedule.schedule_at && this.state.monthly_schedule.schedule_at.hours) || 0}
              monthlyTimeMinutes={
                (this.state.monthly_schedule && this.state.monthly_schedule.schedule_at && this.state.monthly_schedule.schedule_at.minutes) || 0
              }
              onLockedChanged={this.toggleLocked}
              {...this}
            />
          </div>
        </SectionContent>
      </Section>
    );
  }
}

export default withRefresh()(BackupPolicyList);
