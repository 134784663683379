//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../routes";

const DeploymentNotFound = () => {
  const history = useHistory();
  const { deploymentId, projectId } = useParams<{ deploymentId: string; projectId: string }>();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
      <Heading>Deployment not found</Heading>
      <Text>
        Looks like the deployment with ID{" "}
        <Text fontWeight="bold" as="span">
          {deploymentId}
        </Text>{" "}
        does not exist.
      </Text>
      <Text>It might have been deleted recently, please check the list of your existing deployments.</Text>
      <Button colorScheme="green" onClick={() => history.push(Routes.dashboard_project_deploymentsWithId(projectId))}>
        Go back to my deployments
      </Button>
    </Box>
  );
};

export { DeploymentNotFound };
