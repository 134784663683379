//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
import React from "react";
import { InputOnChangeData, Modal } from "semantic-ui-react";
import apiClients from "../../../api/apiclients";
import { Deployment } from "../../../api/lib";
import { Box } from "../../../ui/_box";
import { FormActionButtonSave } from "../../../ui/_buttons";
import { FlexBox } from "../../../ui/_flex";
import { SkipButton, StyledInput, UseCaseCard } from "./UseCaseModalComponents";
import { getHasSkippedUseCaseSelection, setHasSkippedUseCaseSelection } from "./utils";

const USE_CASES = [
  {
    title: "NetworkX",
    id: "networkx",
  },
  {
    title: "Fraud Detection",
    id: "fraud-detection",
  },
  {
    title: "Cyber Security",
    id: "cyber-security",
  },
  {
    title: "Network Asset Management",
    id: "network-asset-management",
  },
  {
    title: "Supply Chain",
    id: "supply-chain",
  },
  {
    title: "Customer360",
    id: "customer360",
  },
  {
    title: "Recommendation Engine",
    id: "recommendation-engine",
  },
  {
    title: "Inventory Management",
    id: "inventory-management",
  },
  {
    title: "Search Engine",
    id: "search-engine",
  },
];

export const UseCaseModal = ({ deployment }: { deployment: Deployment }) => {
  const hasSkippedUseCaseSelection = getHasSkippedUseCaseSelection();
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const [selectedUseCase, setSelectedUseCase] = React.useState<string | undefined>(undefined);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setSelectedUseCase(data.value);
  };
  const onSaveUseCase = async () => {
    apiClients.dataClient.UpdateDeployment({
      ...deployment,
      intended_use_case: selectedUseCase,
    });
  };
  const onSuccess = () => {
    setIsOpen(false);
    setHasSkippedUseCaseSelection(true);
  };
  const onFailure = () => {
    setIsOpen(false);
  };
  if (!isOpen || hasSkippedUseCaseSelection || deployment.intended_use_case) {
    return null;
  }
  return (
    <Modal open size="large" data-testid="useCaseModal">
      <Modal.Header tabIndex={0} data-testid="useCaseHeader">
        <FlexBox direction="column" justify="space-between">
          <h1 className="heading-1">👋 Hello!</h1>
        </FlexBox>
      </Modal.Header>
      <Modal.Content>
        <Box>
          <Box margin="0 0 32px 0" fontSize="20px">
            We know that learning new technologies is not always easy so we want to be your partner along the way. In order to help us provide relevant
            assistance, please let us know your intended use-case:
          </Box>
          <Box margin="0 0 32px 0" gap="6px" display="grid" gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))">
            {USE_CASES.map((useCase) => (
              <UseCaseCard
                key={useCase.id}
                onClick={() => {
                  setSelectedUseCase(useCase.id);
                }}
                isSelected={selectedUseCase === useCase.id}
              >
                <Box fontWeight="600" fontSize="20px">
                  {useCase.title}
                </Box>
              </UseCaseCard>
            ))}
          </Box>
          <Box>
            <StyledInput onChange={onInputChange} label="Others" placeholder="Enter your usecase here" />
          </Box>
        </Box>
      </Modal.Content>
      <FlexBox padding="24px 32px">
        <SkipButton
          onClick={() => {
            setIsOpen(false);
            setHasSkippedUseCaseSelection(true);
          }}
          title="Skip for now"
          data-testid="useCaseModalSkipAction"
        >
          Skip for now
        </SkipButton>
        <FormActionButtonSave
          title="Done"
          primary
          onClick={async () => {
            try {
              await onSaveUseCase();
              onSuccess();
            } catch (e) {
              onFailure();
            }
          }}
          loading={false}
          testID="useCaseModalSaveAction"
        />
      </FlexBox>
    </Modal>
  );
};
