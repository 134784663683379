//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Alert, AlertDescription, AlertIcon, Flex, Text } from "@chakra-ui/react";
import { useCalculateDiskSizesForDownsizing } from "./useCalculateDiskSizesForDownsizing";
import { useFetchNodeSizes } from "./useFetchNodeSizes";
import { useField } from "formik";

const MinSizeForDownsizingAlert = () => {
  const [regionField] = useField("regionId");
  const [modelField] = useField("modelId");
  const { maxUsedDiskSize, minSizeForDownsizing } = useCalculateDiskSizesForDownsizing();
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: regionField.value,
    modelId: modelField.value,
  });

  const minDiskSizes = nodeSizes?.items?.flatMap((nodeSize) => nodeSize.min_disk_size ?? []) || [];
  const smallestMinDiskSize = Math.min(...minDiskSizes);

  if (smallestMinDiskSize && minSizeForDownsizing <= smallestMinDiskSize) {
    return null;
  }

  return (
    <Alert status="info" borderRadius="md" variant="left-accent" colorScheme="green">
      <AlertIcon />
      <AlertDescription>
        <Flex direction="column" gap={1}>
          <Text>
            Current maximum disk usage in the deployment's DBServers is{" "}
            <Text as="span" fontWeight="bold">
              {maxUsedDiskSize.toFixed(0)} GB
            </Text>
          </Text>
          <Text>
            In order to maintain data consistency, the minimum you can downsize to is{" "}
            <Text as="span" fontWeight="bold">
              {minSizeForDownsizing.toFixed(0)} GB
            </Text>
          </Text>
          <Text>Minimum disk sizes allowed have been adjusted accordingly.</Text>
        </Flex>
      </AlertDescription>
    </Alert>
  );
};

export { MinSizeForDownsizingAlert };
