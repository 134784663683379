//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import copy from "copy-to-clipboard";
import { clone, cloneDeep, omit } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  Backup as ApiBackup,
  BackupList as ApiBackupList,
  BackupPolicyList as ApiBackupPolicyList,
  CloneDeploymentFromBackupRequest as ApiCloneDeploymentFromBackupRequest,
  CPUSizeList as ApiCPUSizeList,
  Deployment as ApiDeployment,
  DeploymentCredentialsRequest as ApiDeploymentCredentialsRequest,
  DeploymentFeaturesRequest,
  DeploymentMigration,
  DeploymentReplication,
  IDOptions as ApiIDOptions,
  IDOptions,
  isNotFound,
  ListBackupPoliciesRequest as ApiListBackupPoliciesRequest,
  ListBackupsRequest as ApiListBackupsRequest,
  ListCPUSizesRequest as ApiListCPUSizesRequest,
  NodeSizeList as ApiNodeSizeList,
  NodeSizesRequest as ApiNodeSizesRequest,
  Organization as ApiOrganization,
  Plan as ApiSupportPlan,
  PrepaidDeployment as ApiPrepaidDeployment,
  Project as ApiProject,
  RotateDeploymentServerRequest as ApiRotateDeploymentServerRequest,
  TermsAndConditions as ApiTermsAndConditions,
  UpdateDeploymentRequest as ApiUpdateDeploymentRequest,
} from "../../api/lib";
import {
  IsPrivateEndpointServiceFeatureAvailableRequest as ApiIsPrivateEndpointServiceFeatureAvailableRequest,
  PrivateEndpointService as ApiPrivateEndpointService,
  PrivateEndpointService_Aks as AppiPrivateEndpointService_Aks,
  PrivateEndpointService_Aws as ApiPrivateEndpointService_Aws,
  PrivateEndpointService_Gcp as ApiPrivateEndpointService_Gcp,
} from "../../api/network/v1/network";
import { ListDeploymentNotificationsRequest as ApiListDeploymentNotificationsRequest } from "../../api/notification/v1/notification";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import { ITracking } from "../../tracking/api";
import { Confirm, ConfirmInfo, ErrorMessage, Loading, MainContent, Processing } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import SelectRegionModal from "../backup/SelectRegionModal";
import { HistoryHelper } from "../HistoryHelper";
import PrivateNetworkWizard from "../private-network/PrivateDashboardWizard";
import { AWSPrincipal, RegionProviders } from "../private-network/types";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import { FileUploadProgressView } from "./dataloader/files/FileUploadProgress";
import { getDataVolumeStatus } from "./DataVolumeView";
import { DeploymentDetailsView } from "./DeployementDetailsContentView";
import { UseCaseModal } from "./use-cases/UseCaseModal";
import { DeploymentNotFound } from "./DeploymentNotFound";

// Interface decribing the properties of the deployment details component
interface IDeploymentDetailsProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  project: ApiProject;
  onDeploymentDeleted: (deploymentId: string) => void;
  onDeploymentSelected: (deploymentId: string) => void;
  tracking: ITracking;
}

// Interface decribing the state of the deployment details component
interface IDeploymentDetailsState {
  activeTabIndex: number;
  errorMessage?: string;
  processing: boolean;
  processingUpdatePrepaidDeployment: boolean;
  processingLockDeployment: boolean;
  processingUnlockDeployment: boolean;
  processingRotateServer: boolean;
  confirmInfo?: ConfirmInfo;
  deploymentId?: string;
  deployment?: ApiDeployment;
  isLoadingDeployment?: boolean;
  prepaidDeployment?: ApiPrepaidDeployment;
  showRootPassword: boolean;
  rootPasswordCopied: boolean;
  loadingRootPassword: boolean;
  rootPassword?: string;
  supportPlans?: ApiSupportPlan[];
  node_sizes?: ApiNodeSizeList;
  cpu_sizes?: ApiCPUSizeList;
  expandServers: boolean;
  isDiskWarningShown?: boolean;

  isBackupFeatureAvailable: boolean;
  isBackupUploadFeatureAvailable: boolean;
  backups?: ApiBackupList;
  lastGoodBackups?: ApiBackupList;
  backupPolicies?: ApiBackupPolicyList;

  createBackup: boolean;
  editBackup: boolean;
  editBackupId?: string;
  name: string;
  description: string;
  autoDeleteUpload: boolean;
  autoDeleteUploadInDays: number;
  autoDeleteNoUploadInHours: number;
  uploadBackup: boolean;

  backupPage: number;
  backupPageSize: number;

  currentTC?: ApiTermsAndConditions;
  acceptedTC?: string;
  showAcceptTermsAndConditions: boolean;

  notificationCount?: number | string | undefined;

  isPrivateNetworkAvailable: boolean;
  showPrivateNetworkWizard: boolean | undefined;
  privateNetworkDetails: ApiPrivateEndpointService | undefined;
  isPrivateNetworkActionsAvailable: boolean | undefined;
  providerID: RegionProviders;
  encounteredPECheckError: boolean;

  replicationDetails?: DeploymentReplication;

  isMultiRegionBackupEnabled?: boolean;
  showRegionSelectionModal?: boolean;
  isPauseFeatureAvailable: boolean | undefined;

  isMonitoringFeatureAvailable: boolean;
  migrationStatus?: DeploymentMigration;
}

class DeploymentDetails extends Component<IDeploymentDetailsProps, IDeploymentDetailsState> {
  state: IDeploymentDetailsState = {
    activeTabIndex: 0,
    deploymentId: undefined,
    deployment: undefined,
    prepaidDeployment: undefined,
    showRootPassword: false,
    rootPasswordCopied: false,
    loadingRootPassword: false,
    rootPassword: undefined,
    supportPlans: undefined,
    node_sizes: undefined,
    cpu_sizes: undefined,
    processing: false,
    processingUpdatePrepaidDeployment: false,
    processingLockDeployment: false,
    processingUnlockDeployment: false,
    processingRotateServer: false,
    expandServers: false,
    isDiskWarningShown: false,

    isBackupFeatureAvailable: false,
    isBackupUploadFeatureAvailable: false,
    backups: undefined,
    lastGoodBackups: undefined,
    backupPolicies: undefined,

    createBackup: false,
    editBackup: false,
    editBackupId: undefined,
    name: "",
    description: "",
    autoDeleteUpload: false,
    autoDeleteUploadInDays: 31,
    autoDeleteNoUploadInHours: 6,
    uploadBackup: true,

    backupPage: 0,
    backupPageSize: 5,

    currentTC: undefined,
    acceptedTC: undefined,
    showAcceptTermsAndConditions: false,

    notificationCount: 0,
    isPrivateNetworkAvailable: false,
    showPrivateNetworkWizard: false,
    privateNetworkDetails: undefined,
    isPrivateNetworkActionsAvailable: false,
    providerID: "" as RegionProviders,
    encounteredPECheckError: false,
    replicationDetails: undefined,

    isMultiRegionBackupEnabled: false,
    showRegionSelectionModal: false,
    isPauseFeatureAvailable: false,

    isMonitoringFeatureAvailable: false,
    migrationStatus: {},
  };

  backupId: string = "";
  cloneDeploymentNeedsNewTC: boolean = false;

  refreshDeploymentInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadDeploymentInfo);
  };

  getDeploymentMigrationDetails = async () => {
    const req: ApiIDOptions = {
      id: this.state.deploymentId,
    };

    try {
      const response = await apiClients.replicationClient.GetDeploymentReplication(req);
      this.setState({ replicationDetails: omit(response, "deployment_id") });
    } catch (err) {
      this.setState({ errorMessage: err });
    }
  };

  reloadDeploymentInfo = async () => {
    this.getNotificationCount();
    const idOptions = { id: this.state.deploymentId } as ApiIDOptions;

    let deployment: ApiDeployment = {};

    try {
      this.setState({ isLoadingDeployment: true });
      const fetchDeploymentResponse = await apiClients.dataClient.GetDeployment(idOptions);
      deployment = fetchDeploymentResponse;
    } catch {
      this.setState({ isLoadingDeployment: false });
      return this.setState({ errorMessage: "Deployment fetch failed" });
    }

    const cpuSizesReq = {
      project_id: deployment.project_id,
      deployment_id: deployment.id,
    } as ApiListCPUSizesRequest;
    const cpu_sizes = await apiClients.dataClient.ListCPUSizes(cpuSizesReq);

    const deplModel = deployment.model || {};
    const nodeSizesReq = {
      project_id: deployment.project_id,
      region_id: deployment.region_id,
      model: deplModel.model,
      deployment_id: deployment.id,
    } as ApiNodeSizesRequest;
    const node_sizes = await apiClients.dataClient.ListNodeSizes(nodeSizesReq);
    try {
      const response = await apiClients.replicationClient.GetDeploymentMigration({
        id: deployment.id,
      });

      this.setState({ migrationStatus: response });
    } catch (e) {
      if (isNotFound(e)) {
        this.setState({
          migrationStatus: {},
        });
      }
    }

    const req: DeploymentFeaturesRequest = {
      project_id: deployment.project_id,
      region_id: deployment.region_id,
      model: deplModel.model,
    };
    const { pause, monitoring = false } = await apiClients.dataClient.GetDeploymentFeatures(req);
    this.setState({ isPauseFeatureAvailable: pause, isMonitoringFeatureAvailable: monitoring });

    if (!this.state.deployment) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadDeploymentInfo, deployment.url);
    }

    const isBackupFeatureAvailable = await apiClients.backupClient.IsBackupFeatureAvailable(idOptions);
    const isBackupFeatureAvailableChanged = this.state.isBackupFeatureAvailable != !!isBackupFeatureAvailable.result;
    this.setState(
      {
        deployment: deployment,
        node_sizes: node_sizes,
        cpu_sizes: cpu_sizes,
        isBackupFeatureAvailable: !!isBackupFeatureAvailable.result,
      },
      () => {
        // Make sure to call refreshBackupInfo if the isBackupFeatureAvailable bool inside state has been changed
        useDeploymentStore.setState({ deployment });
        isBackupFeatureAvailableChanged && this.refreshBackupInfo();
        this.setRegionProvider();
      }
    );
    useGlobalStore.setState({ organization: this.props.organization });
    const supportPlans = await apiClients.supportClient.ListPlans({ organization_id: this.props.organization.id });
    this.setState({ supportPlans: supportPlans.items });

    if (deployment && !!deployment.prepaid_deployment_id) {
      const idOptions = { id: deployment.prepaid_deployment_id } as ApiIDOptions;
      const prepaidDeployment = await apiClients.prepaidClient.GetPrepaidDeployment(idOptions);
      this.setState({ prepaidDeployment: prepaidDeployment });
    } else {
      this.setState({ prepaidDeployment: undefined });
    }

    if (deployment) {
      const req = {
        id: this.props.organization.id || "",
      } as ApiIDOptions;
      const termsAndConditions = await apiClients.resourceManagerClient.GetCurrentTermsAndConditions(req);
      // get organization terms and if it's empty, get deployment?
      const acceptedTermsAndConditions = deployment.accepted_terms_and_conditions_id;
      this.setState({ currentTC: termsAndConditions, acceptedTC: acceptedTermsAndConditions });
    }

    await this.reloadBackupInfo();

    // await this.getDeploymentMigrationDetails();

    await this.checkMultiRegionBackupStatus();
  };

  requiresAcceptanceOfTermsAndConditions = () => {
    const tier = this.props.organization.tier || {};
    return !!tier.requires_terms_and_conditions;
  };

  refreshBackupInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadBackupInfo);
  };

  onNextBackupPage = () => {
    this.setState(
      (prev) => ({
        backupPage: prev.backupPage + 1,
      }),
      this.reloadBackupInfo
    );
  };

  onPreviousBackupPage = () => {
    this.setState(
      (prev) => ({
        backupPage: prev.backupPage - 1,
      }),
      this.reloadBackupInfo
    );
  };

  reloadBackupInfo = async () => {
    if (this.state.isBackupFeatureAvailable) {
      const idOptions = { id: this.state.deploymentId } as ApiIDOptions;
      const isBackupUploadFeatureAvailable = await apiClients.backupClient.IsBackupUploadFeatureAvailable(idOptions);
      this.setState({ isBackupUploadFeatureAvailable: !!isBackupUploadFeatureAvailable.result });

      const listBackupsReq = {
        deployment_id: this.state.deploymentId,
        sort_by_created: true,
        sort_descending: true,
        options: {
          page: this.state.backupPage,
          page_size: this.state.backupPageSize,
        },
      } as ApiListBackupsRequest;
      const backups = await apiClients.backupClient.ListBackups(listBackupsReq);
      this.setState({ backups: backups });

      const listLastGoodBackupsReq = {
        deployment_id: this.state.deploymentId,
        sort_by_created: true,
        sort_descending: true,
        good_only: true,
        options: {
          page: 0,
          page_size: 1,
        },
      } as ApiListBackupsRequest;
      const lastGoodBackups = await apiClients.backupClient.ListBackups(listLastGoodBackupsReq);
      this.setState({ lastGoodBackups: lastGoodBackups });

      const listBackupPoliciesReq = { deployment_id: this.state.deploymentId } as ApiListBackupPoliciesRequest;
      const backupPolicies = await apiClients.backupClient.ListBackupPolicies(listBackupPoliciesReq);
      this.setState({ backupPolicies: backupPolicies });
    }
  };

  reloadRootPassword = async (cb: (rootPassword: string) => void) => {
    this.setState({ loadingRootPassword: true });
    try {
      const reqOptions = { deployment_id: this.state.deploymentId } as ApiDeploymentCredentialsRequest;
      const creds = await apiClients.dataClient.GetDeploymentCredentials(reqOptions);
      this.setState({ rootPassword: creds.password }, () => cb(creds.password || ""));
    } finally {
      this.setState({ loadingRootPassword: false });
    }
  };

  getDeploymentName = () => {
    const deployment = this.state.deployment;
    if (deployment) {
      return deployment.name;
    }
    return "";
  };

  onDeleteDeployment = async () => {
    const deploymentName = this.getDeploymentName();
    const confirmInfo = {
      header: "Delete Deployment",
      content: `Are you sure you want to delete deployment '${deploymentName}'?`,
      warning: "This implies deletion of the data stored in the database!",
      confirm: "Delete!",
      invertPositiveNegative: true,
      onConfirm: () => this.onDeleteDeploymentConfirmed(),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onDeleteDeploymentConfirmed = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: this.state.deploymentId || "" } as ApiIDOptions;
      await apiClients.dataClient.DeleteDeployment(idOptions);
      this.props.onDeploymentDeleted && this.props.onDeploymentDeleted(this.state.deploymentId || "");
    } catch (e) {
      this.setState({ errorMessage: `Deployment deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onPauseDeployment = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const idOptions: IDOptions = { id: this.state.deploymentId || "" };
      await apiClients.dataClient.PauseDeployment(idOptions);
      this.refreshDeploymentInfo();
    } catch (err) {
      this.setState({ errorMessage: `Deployment pausing failed: ${err}` });
      reportError(err);
    } finally {
      this.setState({ processing: false });
    }
  };

  onResumeDeployment = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const idOptions = { id: this.state.deploymentId || "" } as ApiIDOptions;
      await apiClients.dataClient.ResumeDeployment(idOptions);
      this.refreshDeploymentInfo();
    } catch (e) {
      this.setState({ errorMessage: `Deployment resuming failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  componentDidMount() {
    const deploymentId = (this.props.match.params as any).deploymentId;
    this.props.subscribe && this.props.subscribe(this.checkPrivateNetworkAvailability, { deployment_id: deploymentId });

    this.setState(
      {
        deploymentId: deploymentId,
      },
      () => {
        //notice inside the reloadDeploymentInfo we use the deploymentId
        this.props.refreshNow && this.props.refreshNow(this.reloadDeploymentInfo);
      }
    );
    this.updateTopMenu();
  }

  getNotificationCount = async () => {
    const req: ApiListDeploymentNotificationsRequest = { deployment_id: this.state.deploymentId, unread_only: true, options: { page: 0, page_size: 10 } };
    const notifications = await apiClients.notificationClient.ListDeploymentNotifications(req);
    const { items = [] } = notifications;
    this.setState({ notificationCount: items.length > 9 ? "9+" : items.length });
  };

  componentDidUpdate() {
    this.updateTopMenu();

    if (this.state.isDiskWarningShown || this.state.confirmInfo) {
      return;
    }
    const dataVolumeStatus = getDataVolumeStatus(this.state.deployment || {});
    if (dataVolumeStatus.isDiskNearingCapacity) {
      const confirmInfo = {
        header: "Almost out of disk!",
        content: (
          <>
            Your deployment is almost running out of disk space. This may result in the inability to write to the deployment or in the worst case unavailability
            of the deployment.
            <Divider hidden />
            Therefore we recommend increasing your disk space right away.
            <Divider hidden />
            To do so, click Yes, increase the disk space and click on Save. After that, all servers will increase their disk space.
          </>
        ),
        warning: "",
        cancelButtonLabel: "Ignore",
        onDenied: () => this.setState({ confirmInfo: undefined, isDiskWarningShown: true }),
        onConfirm: () => {
          this.setState({ confirmInfo: undefined, isDiskWarningShown: true }, this.onEditDeployment);
        },
      } as ConfirmInfo;
      this.setState({
        confirmInfo,
      });
    }
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Projects", Routes.dashboard_organization_projectsWithId(this.props.organization.id || "")),
      new BreadCrumbItem(this.props.project.name || "", Routes.dashboard_projectWithId(this.props.project.id || "")),
      new BreadCrumbItem("Deployments", Routes.dashboard_project_deploymentsWithId(this.props.project.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("deployment");
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  refreshRootPassword = (cb: (rootPassword: string) => void) => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadRootPassword(cb));
  };

  onShowRootPasswordToggle = () => {
    if (!this.state.rootPassword && !this.state.showRootPassword) {
      // Load root password
      this.refreshRootPassword((rootPassword: string) => {});
    }
    this.setState((state: IDeploymentDetailsState) => {
      return { showRootPassword: !state.showRootPassword };
    });
  };

  onCopyRootPassword = () => {
    this.refreshRootPassword((rootPassword: string) => {
      copy(rootPassword);
      this.setState(
        { rootPasswordCopied: true },
        () => this.props.setTimeout && this.props.setTimeout(() => this.setState({ rootPasswordCopied: false }), 2000)
      );
    });
  };

  onTabChange = (activeTabIndex: number) => {
    this.setState({ activeTabIndex });
  };

  onNewRoleBinding = () => {
    const project_id = this.props.project.id || "";
    const deployment = this.state.deployment || {};
    const deployment_id = deployment.id || "";
    HistoryHelper.push(
      this.props.history,
      Routes.dashboard_project_deployment_policy_createWithId(project_id, deployment_id),
      this.props.topMenuInfo.getTitle()
    );
  };

  onEditDeployment = () => {
    const project_id = this.props.project.id || "";
    const deployment = this.state.deployment || {};
    if (deployment.status?.read_only) {
      return;
    }
    const deployment_id = deployment.id || "";
    HistoryHelper.push(this.props.history, Routes.dashboard_project_deployment_editWithId(project_id, deployment_id), this.props.topMenuInfo.getTitle());
  };

  onClickCreateBackup = () => {
    this.setState({
      createBackup: true,
      name: "",
      description: "",
      autoDeleteUpload: false,
      autoDeleteUploadInDays: 31,
      autoDeleteNoUploadInHours: 6,
      uploadBackup: this.state.isBackupUploadFeatureAvailable,
    });
  };

  onClickCancelCreateBackup = () => {
    this.setState({ createBackup: false });
  };

  onClickCancelEditBackup = () => {
    this.setState({ editBackup: false });
  };

  updateName = (newValue: string) => {
    this.setState({ name: newValue });
  };

  updateDescription = (newValue: string) => {
    this.setState({ description: newValue });
  };

  toggleAutoDeleteUpload = () => {
    this.setState((state: IDeploymentDetailsState) => {
      return { autoDeleteUpload: !state.autoDeleteUpload };
    });
  };

  updateAutoDeleteUploadInDays = (newValue: number) => {
    this.setState({ autoDeleteUploadInDays: newValue });
  };

  updateAutoDeleteNoUploadInHours = (newValue: number) => {
    this.setState({ autoDeleteNoUploadInHours: newValue });
  };

  toggleUploadBackup = () => {
    this.setState((state: IDeploymentDetailsState) => {
      return { uploadBackup: !state.uploadBackup };
    });
  };

  getAutoDeleteFromState(): moment.Moment | undefined {
    if (this.state.uploadBackup) {
      if (this.state.autoDeleteUpload) {
        return moment(Date.now()).utc().add(this.state.autoDeleteUploadInDays, "day");
      }
    } else {
      const mom = moment(Date.now()).utc().add(this.state.autoDeleteNoUploadInHours, "hour").subtract(2, "minutes");
      console.log(mom);
      return mom;
    }
    return undefined;
  }

  onClickSaveCreateBackup = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const backup = {
        deployment_id: this.state.deploymentId || "",
        name: this.state.name,
        description: this.state.description,
        upload: this.state.uploadBackup,
        auto_deleted_at: this.getAutoDeleteFromState(),
      } as ApiBackup;
      await apiClients.backupClient.CreateBackup(backup);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false, createBackup: false });
  };

  onClickSaveEditBackup = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const backup = {
        id: this.state.editBackupId,
        deployment_id: this.state.deploymentId || "",
        name: this.state.name,
        description: this.state.description,
        upload: this.state.uploadBackup,
        auto_deleted_at: this.getAutoDeleteFromState(),
      } as ApiBackup;
      await apiClients.backupClient.UpdateBackup(backup);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false, editBackup: false });
  };

  getBackupName = (id: string): string => {
    const backups = this.state.backups;
    if (backups && backups.items) {
      const backup = backups.items.find((g) => g.id == id);
      if (backup) {
        return `${backup.name} (created: ${moment(backup.created_at).fromNow()})`;
      }
    }
    return "";
  };

  onClickBackupRestore = (id: string) => {
    const backupName = this.getBackupName(id);
    const confirmInfo = {
      header: "Restore Backup",
      content: `Are you sure you want to restore backup '${backupName}'?`,
      warning: "This implies that your database will be unavailabe for some time!",
      confirm: "Restore!",
      invertPositiveNegative: true,
      onConfirm: () => this.onRestoreBackupConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onRestoreBackupConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.backupClient.RestoreBackup(idOptions);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup restore failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onClickBackupEdit = (id: string) => {
    const backups = this.state.backups;
    if (backups && backups.items) {
      const backup = backups.items.find((g) => g.id == id);
      if (backup) {
        this.setState({
          editBackup: true,
          editBackupId: id,
          name: backup.name || "",
          description: backup.description || "",
          autoDeleteUpload: backup.upload ? !!backup.auto_deleted_at : false,
          autoDeleteUploadInDays: backup.upload && backup.auto_deleted_at ? moment(backup.auto_deleted_at).diff(Date.now(), "day") + 1 : 31,
          autoDeleteNoUploadInHours: !backup.upload && backup.auto_deleted_at ? moment(backup.auto_deleted_at).diff(Date.now(), "hour") + 1 : 6,
          uploadBackup: !!backup.upload,
        });
      }
    }
  };

  onClickBackupDownload = (id: string) => {
    const backupName = this.getBackupName(id);
    const confirmInfo = {
      header: "Download Backup",
      content: `Are you sure you want to download backup '${backupName}'?`,
      warning: "This implies that your data-storage in your cluster will grow!",
      onConfirm: () => this.onDownloadBackupConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onDownloadBackupConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.backupClient.DownloadBackup(idOptions);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup download failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onClickBackupDelete = (id: string) => {
    const backupName = this.getBackupName(id);
    const confirmInfo = {
      header: "Delete Backup",
      content: `Are you sure you want to delete backup '${backupName}'?`,
      warning: "This implies deletion of the data stored in the cloud as well!",
      invertPositiveNegative: true,
      onConfirm: () => this.onDeleteBackupConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onDeleteBackupConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.backupClient.DeleteBackup(idOptions);
      this.refreshBackupInfo();
    } catch (e) {
      this.setState({ errorMessage: `Backup deletion failed: ${e}`, backupPage: 0 });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onUpdatePrepaidDeployment = async () => {
    const deployment = this.state.deployment;
    if (deployment) {
      try {
        this.setState({ processingUpdatePrepaidDeployment: true, errorMessage: undefined, confirmInfo: undefined });
        const req = {
          prepaid_deployment_id: deployment.prepaid_deployment_id,
        } as ApiUpdateDeploymentRequest;
        await apiClients.prepaidClient.UpdateDeployment(req);
        this.refreshDeploymentInfo();
      } catch (e) {
        this.setState({ errorMessage: `Updating prepaid deployment failed: ${e}` });
        reportError(e);
      }
      this.setState({ processingUpdatePrepaidDeployment: false });
    }
  };

  onLockDeployment = async () => {
    const deployment = this.state.deployment;
    if (deployment) {
      try {
        this.setState({ processingLockDeployment: true, errorMessage: undefined, confirmInfo: undefined });
        const req = cloneDeep(deployment);
        req.locked = true;
        await apiClients.dataClient.UpdateDeployment(req);
        this.refreshDeploymentInfo();
      } catch (e) {
        this.setState({ errorMessage: `Locking deployment failed: ${e}` });
        reportError(e);
      }
      this.setState({ processingLockDeployment: false });
    }
  };
  onUnlockDeployment = async () => {
    const deployment = this.state.deployment;
    if (deployment) {
      try {
        this.setState({ processingUnlockDeployment: true, errorMessage: undefined, confirmInfo: undefined });
        const req = cloneDeep(deployment);
        req.locked = false;
        await apiClients.dataClient.UpdateDeployment(req);
        this.refreshDeploymentInfo();
      } catch (e) {
        this.setState({ errorMessage: `Unlocking deployment failed: ${e}` });
        reportError(e);
      }
      this.setState({ processingUnlockDeployment: false });
    }
  };

  cloneDeployment = async (id: string, needsNewTC: boolean, regionID?: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const req: ApiCloneDeploymentFromBackupRequest = !!regionID ? { backup_id: id, region_id: regionID } : { backup_id: id };
      const tandc = this.state.currentTC;
      if (needsNewTC && tandc && tandc.id) {
        req.accepted_terms_and_conditions_id = tandc.id;
      }
      const clone = await apiClients.replicationClient.CloneDeploymentFromBackup(req);
      this.setState(
        {
          processing: false,
          deploymentId: clone.id,
          activeTabIndex: 0,
        },
        () => {
          this.backupId = "";
          this.cloneDeploymentNeedsNewTC = false;
          this.props.onDeploymentSelected(clone.id || "");
        }
      );
    } catch (e) {
      this.setState({ errorMessage: `Clone deployment from backup failed: ${e}`, processing: false });
      reportError(e);
    }
  };

  onClickCloneDeploymentFromBackup = async (id: string, needsNewTC: boolean) => {
    if (this.state.isMultiRegionBackupEnabled) {
      this.setState({ showRegionSelectionModal: true });
      this.backupId = id;
      this.cloneDeploymentNeedsNewTC = needsNewTC;
      return;
    }
    this.cloneDeployment(id, needsNewTC);
  };

  onRotateServer = (serverID: string) => {
    const deploymentName = this.getDeploymentName();
    const confirmInfo = {
      header: "Rotate server",
      content: `Are you sure you want to request the rotation of server '${serverID}' in deployment '${deploymentName}'?`,
      warning: "This implies that all client connections to this server will need to reconnect.",
      confirm: "Rotate!",
      onConfirm: () => this.onRotateServerConfirmed(serverID),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onRotateServerConfirmed = async (serverID: string) => {
    const deployment = this.state.deployment;
    if (deployment) {
      try {
        this.setState({ processingRotateServer: true, errorMessage: undefined, confirmInfo: undefined });
        const req = {
          deployment_id: deployment.id,
          server_id: serverID,
        } as ApiRotateDeploymentServerRequest;
        await apiClients.dataClient.RotateDeploymentServer(req);
        this.refreshDeploymentInfo();
      } catch (e) {
        this.setState({ errorMessage: `Requesting server rotation failed: ${e}` });
        reportError(e);
      }
      this.setState({ processingRotateServer: false });
    }
  };

  onClickCancelShowAcceptTermsAndConditions = () => {
    this.setState({ showAcceptTermsAndConditions: false });
  };

  onClickTriggerShowAcceptTermsAndConditions = () => {
    this.setState({ showAcceptTermsAndConditions: true });
  };

  onUpdateNameAndDescription = async (name: string, description: string) => {
    // Note that we do not try/catch here, since that is handled in the top menu.
    const deployment = clone(this.state.deployment || {});
    deployment.name = name;
    deployment.description = description;
    await apiClients.dataClient.UpdateDeployment(deployment);
    this.refreshDeploymentInfo();
  };

  onToggleExpandServers = () => {
    this.setState((old) => {
      return { expandServers: !old.expandServers };
    });
  };

  checkPrivateNetworkAvailability = async () => {
    const { deploymentId, deployment = {} } = this.state;
    const { organization = {}, hasPermissionByUrl } = this.props;
    const { region_id: regionId } = deployment;
    const { id: organizationId } = organization;
    const { networkClient } = apiClients;
    const isAllowed = hasPermissionByUrl && hasPermissionByUrl(deployment.url || "", ResourceType.Deployment, "network.privateendpointservice.get-feature");

    if (!isAllowed) {
      return;
    }

    const req: ApiIsPrivateEndpointServiceFeatureAvailableRequest = {
      deployment_id: deploymentId,
      organization_id: organizationId,
      region_id: regionId,
    };

    try {
      this.setState({ encounteredPECheckError: false });
      const response = await networkClient.IsPrivateEndpointServiceFeatureAvailable(req);
      const { available = false } = response;
      this.setState({ isPrivateNetworkAvailable: available }, () => {
        available && this.getPrivateNetworkDetails();
      });
    } catch (error) {
      // Ignore first error and retry.
      if (this.state.encounteredPECheckError) {
        reportError(error);
        this.setState({ errorMessage: `Failed to check if private endpoint is available: ${error}` });
        console.warn("Encountered error while fetching private endpoint availability. Retrying request.");
      } else {
        this.setState({ encounteredPECheckError: true });
        this.checkPrivateNetworkAvailability();
      }
    }
  };

  getPrivateNetworkDetails = async () => {
    const { deploymentId, isPrivateNetworkAvailable, deployment = {} } = this.state;
    const { hasPermissionByUrl } = this.props;
    const isAllowed =
      hasPermissionByUrl &&
      hasPermissionByUrl(deployment.url || "", ResourceType.Deployment, "network.privateendpointservice.get-feature") &&
      hasPermissionByUrl(deployment.url || "", ResourceType.Deployment, "network.privateendpointservice.get-by-deployment-id");

    if (!isPrivateNetworkAvailable && !isAllowed) {
      return;
    }
    const { networkClient } = apiClients;

    const req: ApiIDOptions = {
      id: deploymentId,
    };

    try {
      const response = await networkClient.GetPrivateEndpointServiceByDeploymentID(req);
      this.setState({ privateNetworkDetails: response });
    } catch (error) {
      if (isNotFound(error)) {
        this.setState({ privateNetworkDetails: undefined });
      } else {
        reportError(error);
        this.setState({ errorMessage: `Failed to get private endpoint details: ${error}` });
      }
    }
  };

  updatePrivateNetworkDetails = async (updatedDetails: Partial<ApiPrivateEndpointService>): Promise<{ error: unknown } | undefined> => {
    const { deployment = {} } = this.state;
    const { hasPermissionByUrl } = this.props;
    const isAllowed = hasPermissionByUrl && hasPermissionByUrl(deployment.url || "", ResourceType.Deployment, "network.privateendpointservice.update");

    if (!isAllowed) return { error: "You do not have permission to update private endpoint details" };

    const { privateNetworkDetails } = this.state;
    const { networkClient } = apiClients;
    const payload: ApiPrivateEndpointService = { ...privateNetworkDetails, ...updatedDetails };
    try {
      await networkClient.UpdatePrivateEndpointService(payload);
      this.setState({ privateNetworkDetails: payload });
    } catch (e) {
      return { error: e };
    }
  };

  handleSubscriptionIDsChange = (subscriptionIDs: string[]): Promise<{ error: unknown } | undefined> => {
    const updatedSubscriptionIDs: AppiPrivateEndpointService_Aks = {
      client_subscription_ids: subscriptionIDs,
    };
    return this.updatePrivateNetworkDetails({ aks: updatedSubscriptionIDs });
  };

  handleAWSPrincipalsChange = (principals: AWSPrincipal[]): Promise<{ error: unknown } | undefined> => {
    const updatedPrincipals: ApiPrivateEndpointService_Aws = {
      aws_principals: principals,
    };
    return this.updatePrivateNetworkDetails({ aws: updatedPrincipals });
  };

  handleGCPProjectNamesChange = (projectNames: string[]): Promise<{ error: unknown } | undefined> => {
    const updatedProjectNames: ApiPrivateEndpointService_Gcp = {
      projects: projectNames,
    };
    return this.updatePrivateNetworkDetails({ gcp: updatedProjectNames });
  };

  handleAlternateDNSNamesChange = (data: { alternateDNSNames: string[]; enablePrivateDNS?: boolean }): Promise<{ error: unknown } | undefined> => {
    const updatedAlternateDNSNames: Partial<ApiPrivateEndpointService> = {
      alternate_dns_names: data.alternateDNSNames,
    };
    if (data.enablePrivateDNS !== undefined) {
      updatedAlternateDNSNames.enable_private_dns = data.enablePrivateDNS;
    }
    return this.updatePrivateNetworkDetails(updatedAlternateDNSNames);
  };

  setRegionProvider = async (): Promise<void> => {
    if (this.state.providerID) {
      return;
    }
    try {
      const { deployment = {} } = this.state;
      const { region_id: regionId } = deployment;

      const regionID: ApiIDOptions = {
        id: regionId,
      };

      const region = await apiClients.platformClient.GetRegion(regionID);
      const { provider_id: providerID = "" } = region;

      useDeploymentStore.setState({ region: region, provider: { id: providerID, name: region.location } });
      this.setState({ providerID: providerID as RegionProviders });
    } catch (err) {
      this.setState({ errorMessage: err });
    }
  };

  checkMultiRegionBackupStatus = async () => {
    const { deployment = {} } = this.state;
    const { id = "" } = deployment;
    const { hasPermissionByUrl } = this.props;
    const isAllowed = hasPermissionByUrl && hasPermissionByUrl(deployment.url || "", ResourceType.Deployment, "backup.feature.get");
    if (!isAllowed) return false;

    try {
      const req: ApiIDOptions = {
        id,
      };
      const { result: hasMultiRegionBackupEnabled } = await apiClients.backupClient.IsMultiRegionBackupUploadFeatureAvailable(req);
      this.setState({ isMultiRegionBackupEnabled: !!hasMultiRegionBackupEnabled });
    } catch (err) {
      console.warn("Could not get multi-region backup status");
    }
  };

  render() {
    const deployment = this.state.deployment;
    const isLoadingDeployment = this.state.isLoadingDeployment;
    const isError = !!this.state.errorMessage;
    const tier = this.props.organization.tier || {};
    const node_sizes = this.state.node_sizes || {};
    const cpu_sizes = this.state.cpu_sizes || {};

    if (!deployment && !isLoadingDeployment && !!isError) {
      return <DeploymentNotFound />;
    }

    if (!deployment) {
      this.props.topMenuInfo.setTitles("", "");
      return <Loading />;
    }

    const hasPermission = (p: Permission) => {
      const hasPermissionByUrl = this.props.hasPermissionByUrl;
      return !!(hasPermissionByUrl && hasPermissionByUrl(deployment.url || "", ResourceType.Deployment, p));
    };
    const isDeploymentEditAllowed = hasPermission("data.deployment.update");
    const tierHasAuditLog = !!tier.has_auditlog_feature;

    this.props.topMenuInfo.setTitles(
      deployment.name || "",
      deployment.description || "",
      isDeploymentEditAllowed ? this.onUpdateNameAndDescription : undefined
    );

    return (
      <>
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processing} message="Processing, please wait..." />
        <Processing active={this.state.processingUpdatePrepaidDeployment} message="Updating prepaid deployment, please wait..." />
        <Processing active={this.state.processingLockDeployment} message="Locking deployment, please wait..." />
        <Processing active={this.state.processingUnlockDeployment} message="Unlocking deployment, please wait..." />
        <Processing active={this.state.processingRotateServer} message="Requesting server rotation, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        {this.state.showRegionSelectionModal && (
          <SelectRegionModal
            {...this.props}
            onClose={() => {
              this.setState({ showRegionSelectionModal: false });
              this.backupId = "";
              this.cloneDeploymentNeedsNewTC = false;
            }}
            onClick={(regionId: string) => {
              this.cloneDeployment(this.backupId, this.cloneDeploymentNeedsNewTC, regionId);
              this.setState({ showRegionSelectionModal: false });
            }}
          />
        )}
        <MainContent>
          {this.state.showPrivateNetworkWizard && (
            <PrivateNetworkWizard
              name={deployment.name}
              onConfirm={(privateNetworkDetails: ApiPrivateEndpointService) => {
                this.setState({ privateNetworkDetails, showPrivateNetworkWizard: false });
              }}
              onClose={() => {
                this.setState({ showPrivateNetworkWizard: false });
              }}
              deploymentID={this.state.deploymentId}
              providerID={this.state.providerID}
            />
          )}
          <FileUploadProgressView />
          <DeploymentDetailsView
            {...this.props}
            {...this.state}
            {...this}
            isDiskAlmostFull={this.state.isDiskWarningShown}
            deployment={deployment}
            node_sizes={node_sizes}
            cpu_sizes={cpu_sizes}
            supportPlans={this.state.supportPlans || []}
            tierHasSupportPlans={tier.has_support_plans || false}
            tierHasAuditLogFeature={tierHasAuditLog}
            notificatonCount={this.state.notificationCount}
            handleConversionToPrivateNetwork={() => this.setState({ showPrivateNetworkWizard: true })}
            privateNetworkDetails={this.state.privateNetworkDetails}
            onSubscriptionIDChange={this.handleSubscriptionIDsChange}
            onAlternateDNSNamesChange={this.handleAlternateDNSNamesChange}
            onAWSPrincipalsChange={this.handleAWSPrincipalsChange}
            onGCPProjectNameChange={this.handleGCPProjectNamesChange}
            isPrivateNetworkAvailable={this.state.isPrivateNetworkAvailable}
            provider={this.state.providerID}
            passwordLastRotatedDate={deployment.created_at === deployment.last_root_password_rotated_at ? undefined : deployment.last_root_password_rotated_at}
            isPauseFeatureAvailable={this.state.isPauseFeatureAvailable}
            isMonitoringFeatureAvailable={this.state.isMonitoringFeatureAvailable}
            onPauseDeployment={this.onPauseDeployment}
          />
        </MainContent>
        <UseCaseModal deployment={deployment} />
      </>
    );
  }
}

export default withRefresh()(DeploymentDetails);
