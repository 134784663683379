//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React, { useRef } from "react";
import { Deployment } from "../../api/lib";
import { NewDeploymentFormValues } from "../newDeployment/newDeploymentForm.types";

const DownsizingAlertDialog = ({
  onClose,
  isOpen,
  updateDeployment,
}: {
  onClose: () => void;
  isOpen: boolean;
  updateDeployment: (values: NewDeploymentFormValues) => Promise<Deployment | undefined>;
}) => {
  const { values } = useFormikContext<NewDeploymentFormValues>();

  const cancelRef = useRef<HTMLButtonElement>(null);

  const onUpdateDeployment = async () => {
    await updateDeployment(values);
  };

  return (
    <AlertDialog isCentered onClose={onClose} isOpen={isOpen} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Reduce disk size</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Flex gap={4} direction="column">
            <Text>Are you sure you want to reduce the disk size?</Text>
            <Text>This operation may take longer than scaling up due to data movement within the cluster.</Text>
            <Text>We recommend performing this downsizing outside of peak usage times.</Text>
          </Flex>
        </AlertDialogBody>
        <AlertDialogFooter gap={3}>
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="red" onClick={onUpdateDeployment}>
            Proceed
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DownsizingAlertDialog };
