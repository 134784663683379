//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Box } from "@chakra-ui/react";
import { useFeature } from "flagged";
import _ from "lodash";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import apiClients from "../api/apiclients";
import { ListOptions as ApiListOptions } from "../api/common/v1/common";
import { User as ApiUser } from "../api/lib";
import {
  Organization as ApiOrganization,
  OrganizationList as ApiOrganizationList,
  ProjectList as ApiProjectList,
} from "../api/resourcemanager/v1/resourcemanager";
import { useAuthContext } from "../auth/AuthContextProvider";
import { EnterpriseTierID, FreeTierID, PoCTierID, ProTierID } from "../constants";
import { Routes } from "../routes";
import { Loading } from "../ui/lib";
import { useSetupAppcues } from "../useSetupAppcues";
import { useWithRefresh } from "../util/WithRefreshContext";
import { TopMenuInfo } from "./TopMenuInfo";

interface DashboardProps {
  organizations: ApiOrganizationList | undefined;
  selectedOrganization: ApiOrganization | undefined;
  topMenuInfo: TopMenuInfo;
  user: ApiUser | undefined;
  pendingOrganizationInvites: number;
  onClickHelp: () => void;
  onClickExamples: () => void;
  onClickStatusPage: () => void;
  onClickLogout: () => void;
  reloadOrganizationInvites: () => void;
  onOrganizationSelected: (organizationId: string) => void;
  onNewOrganizationCreated: (organizationId: string) => void;
  onOrganizationDeleted: (organizationId: string) => void;
  onReloadOrganization: () => void;
  onClickSupportRequest: () => void;
}

interface DashboardContextType extends DashboardProps {
  projects: ApiProjectList | undefined;
  onNewProjectCreated: (projectId: string, fromDashboardHome: boolean) => void;
  onProjectDeleted: () => void;
  onOpenInternalDashboard: () => void;
  debugMode: boolean;
  isFreeTier: boolean;
  isProTier: boolean;
  isPOCTier: boolean;
  isPerpetualFreeTrialAvailable: boolean;
  isEnterpriseTier: boolean;
  selectedOrganization: ApiOrganization;
  reloadProjects: () => void;
}

const DashboardContext = React.createContext<DashboardContextType>({} as any);
export const useDashboardContext = () => React.useContext(DashboardContext);

const DashboardContextProvider = (
  props: DashboardProps & {
    children: React.ReactNode;
  }
) => {
  const {
    topMenuInfo,
    organizations,
    selectedOrganization,
    onClickLogout,
    reloadOrganizationInvites,
    onOrganizationSelected,
    onNewOrganizationCreated,
    onOrganizationDeleted,
    onReloadOrganization,
    onClickSupportRequest,
    user,
    pendingOrganizationInvites,
    onClickHelp,
    onClickExamples,
    onClickStatusPage,
  } = props;
  useSetupAppcues({ selectedOrganization });
  const { refreshNow, subscribeUrl } = useWithRefresh();
  const history = useHistory();
  const { auth } = useAuthContext();
  const [subscribeNeeded, setSubscribeNeeded] = React.useState(false);
  const [projects, setProjects] = React.useState<ApiProjectList>();

  const isPerpetualFreeTrialAvailable = !!useFeature("perpetuallyFreeDeployments");

  useEffect(() => {
    setSubscribeNeeded(true);
    setProjects(undefined);
  }, [selectedOrganization]);

  useEffect(() => {
    if (subscribeNeeded) {
      setSubscribeNeeded(false);
      selectedOrganization && subscribeProjects(selectedOrganization);
    }
  }, [subscribeNeeded, selectedOrganization]);

  const subscribeProjects = async (organization: ApiOrganization) => {
    subscribeUrl && subscribeUrl(reloadProjects, `${organization.url}/Project/*`);
  };
  const reloadProjects = async () => {
    if (selectedOrganization) {
      const listOptions = { context_id: selectedOrganization.id } as ApiListOptions;
      const projects = await apiClients.resourceManagerClient.ListProjects(listOptions);
      if (projects.items) {
        projects.items = _.orderBy(projects.items, "name");
      }
      setProjects(projects);
    }
  };
  const refreshProjects = async () => {
    refreshNow?.(reloadProjects);
  };
  const onProjectDeleted = () => {
    refreshProjects();
    history.replace(Routes.dashboard);
  };
  const onNewProjectCreated = (projectId: string, fromDashboardHome: boolean) => {
    refreshProjects();
    if (fromDashboardHome) {
      //go back to the dashboard (home) page, so the deployment can be created from there as onboarding step.
      history.replace(Routes.dashboard);
    } else {
      history.replace(Routes.dashboard_project_detailsWithId(projectId));
    }
  };
  const onOpenInternalDashboard = () => {
    window.open(`/internal/dashboard?token=${auth.getAccessToken()}`);
  };

  const debug = window.DEBUG;
  useEffect(() => {
    if (selectedOrganization) {
      reloadProjects();
    }
  }, [selectedOrganization]);

  if (!organizations || !selectedOrganization) {
    return (
      <Box position="fixed" width="100%" left="0" top="0" bottom="0px">
        <Loading />
      </Box>
    );
  }
  const isFreeTier = selectedOrganization.tier?.id === FreeTierID;
  const isProTier = selectedOrganization.tier?.id === ProTierID;
  const isPOCTier = selectedOrganization.tier?.id === PoCTierID;
  const isEnterpriseTier = selectedOrganization.tier?.id === EnterpriseTierID;

  return (
    <DashboardContext.Provider
      value={{
        onClickLogout,
        reloadOrganizationInvites,
        onOrganizationSelected,
        onNewOrganizationCreated,
        onOrganizationDeleted,
        onReloadOrganization,
        onClickSupportRequest,
        topMenuInfo,
        organizations,
        selectedOrganization,
        projects,
        onNewProjectCreated,
        onProjectDeleted,
        onOpenInternalDashboard,
        debugMode: debug,
        isFreeTier,
        isProTier,
        isPOCTier,
        isEnterpriseTier,
        reloadProjects,
        user,
        pendingOrganizationInvites,
        onClickHelp,
        onClickExamples,
        onClickStatusPage,
        isPerpetualFreeTrialAvailable,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export { DashboardContextProvider };
