//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";

const DISK_SIZE_ALLOWANCE_MULTIPLIER = 1.5;
const GB_IN_BYTES = 2 ** 30;

const convertBytesToGB = (bytes: number) => {
  return bytes / GB_IN_BYTES;
};

export const useCalculateDiskSizesForDownsizing = () => {
  const { data: deployment } = useFetchDeploymentForEdit();
  const { servers = [] } = deployment?.status || {};

  const dbServerUsedDiskSizes = servers.reduce((acc: number[], server) => {
    if (server.type === "Dbserver") {
      const usedBytes = server.data_volume_info?.used_bytes || 0;
      acc.push(usedBytes);
    }
    return acc;
  }, []);

  const maxUsedDiskSize = Math.max(...dbServerUsedDiskSizes);
  const minSizeForDownsizing = dbServerUsedDiskSizes.length ? maxUsedDiskSize * DISK_SIZE_ALLOWANCE_MULTIPLIER : 0;

  const maxUsedDiskSizeGB = Math.ceil(convertBytesToGB(maxUsedDiskSize));
  const minSizeForDownsizingGB = Math.ceil(convertBytesToGB(minSizeForDownsizing));

  return { maxUsedDiskSize: maxUsedDiskSizeGB, minSizeForDownsizing: minSizeForDownsizingGB };
};
