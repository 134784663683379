//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { ReactNode } from "react";
import { Modal, Portal } from "semantic-ui-react";
import { FormActionButtonResume } from "../../ui/lib";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";

interface IDeploymentPausedModalArgs {
  trigger: ReactNode;
  onResumeDeployment: () => void;
  onClick?: () => void;
}

export const DeploymentPausedModal = ({ trigger, onResumeDeployment, onClick }: IDeploymentPausedModalArgs) => {
  const { deployment } = useDeploymentStore();
  const isPaused = !!deployment.is_paused;

  if (isPaused) {
    return (
      <Modal trigger={trigger}>
        <Modal.Header>Deployment paused</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className="para">Your deployment is currently paused.</p>
            <p className="para">Before you can continue with this action, you must first resume your deployment.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonResume primary onClick={onResumeDeployment} />
        </Modal.Actions>
      </Modal>
    );
  }
  return (
    <Portal trigger={trigger} onOpen={onClick}>
      <span />
    </Portal>
  );
};
