import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import apiClients from "./api/apiclients";
import { Timestamp } from "./api/googleTypes";
import { Organization } from "./api/lib";
import { useFetchCurrentUser } from "./useFetchCurrentUser";

interface AppCuesUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  createdAt: string;
  companyName: string;
}

const formatDate = (timestamp: Timestamp) => {
  return moment.utc(timestamp).format("DD MMM YYYY HH:mm");
};

const useFetchOrganizationDeployments = ({ organizationId }: { organizationId: string | undefined }) => {
  const fetchOrganizationDeployments = async () => {
    return await apiClients.dataClient.ListDeploymentsByFilter({ organization_id: organizationId });
  };

  return useQuery(["organizationDeployments", organizationId], fetchOrganizationDeployments, {
    enabled: !!organizationId,
  });
};

export const useSetupAppcues = ({ selectedOrganization }: { selectedOrganization: Organization | undefined }) => {
  const { data: currentUser } = useFetchCurrentUser();
  const { data: deployments } = useFetchOrganizationDeployments({ organizationId: selectedOrganization?.id });
  const deploymentsList = deployments?.items || [];
  const [user, setUser] = useState<AppCuesUser | undefined>(undefined);

  const getUser = async () => {
    const user = await window.Appcues.user();
    setUser(user);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    window.Appcues.identify(currentUser.id, {
      email: currentUser.email,
      firstName: currentUser.given_name,
      lastName: currentUser.family_name,
      name: currentUser.name,
      createdAt: formatDate(currentUser.created_at!),
      companyName: currentUser.company_name,
    });

    getUser();
  }, []);

  useEffect(() => {
    if (!selectedOrganization || !user?.email) {
      return;
    }

    const expiration = deploymentsList[0]?.expiration;

    const freeTierExpiresAt =
      selectedOrganization.tier?.id === "free" && deploymentsList.length > 0 && expiration && expiration.expires_at
        ? formatDate(expiration.expires_at)
        : undefined;

    window.Appcues.group(selectedOrganization.id, {
      createdAt: formatDate(selectedOrganization.created_at!),
      planTier: selectedOrganization.tier?.id,
      companyName: selectedOrganization.name,
      freeTierExpiresAt: freeTierExpiresAt,
    });
  }, [selectedOrganization?.id, selectedOrganization?.tier?.id, deploymentsList.length, user?.email]);
};
