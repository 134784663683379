//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Icon, Loader, Popup, Table } from "semantic-ui-react";
import semver from "semver";
import {
  Backup as ApiBackup,
  BackupList as ApiBackupList,
  Backup_Status as ApiBackup_Status,
  Deployment as ApiDeployment,
  IDOptions as ApiIDOptions,
  Region as ApiRegion,
  TermsAndConditions as ApiTermsAndConditions,
} from "../../api/lib";
import { ListAction, Loading } from "../../ui/lib";
import { humanizeFileSize } from "../../util/FileSize";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { DeploymentPausedModal } from "../pause/DeploymentPausedModal";
import { CloneAcceptTermsAndConditionsModal } from "./CloneAcceptTermsAndConditionsModal";
import CopyBackupToDifferentRegion from "./CopyBackupToDifferentRegion";
import apiClients from "../../api/apiclients";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useDeploymentPermissions } from "../deployment/useDeploymentPermissions";

interface ICardValueView {
  bu: ApiBackup;
  status: ApiBackup_Status;
}

const InCloudStorageValueView = ({ ...args }: ICardValueView) => {
  const bu = args.bu;
  const status = args.status;
  const hasStatus = !!bu.status;
  const isFailed = status && status.is_failed;
  const uploadStatus = status.upload_status;
  const uploaded = uploadStatus && uploadStatus.uploaded;
  const needsUpload = bu.upload && !uploaded;
  const uploadedAt = uploadStatus && uploadStatus.uploaded_at;
  const uploadSize = (uploadStatus && uploadStatus.size_bytes) || 0;
  const uploadDeleting = uploaded && !bu.upload;

  const downloadSpec = bu.download;
  const downloadStatus = status.download_status;
  const downloadSpecRev = (downloadSpec && downloadSpec.revision) || 0;
  const downloaded = downloadStatus && downloadStatus.downloaded;
  const needsDownload = downloadSpec && downloadSpecRev > 0 && !downloaded;
  const startDownload = bu.download && bu.download.last_updated_at;
  const downloadedAt = downloadStatus && downloadStatus.downloaded_at;

  // Do we have a status?
  if (!hasStatus || isFailed) {
    // Unknown
    return <span>-</span>;
  }

  // Are we uploading?
  if (needsUpload) {
    return <span>Upload: {status.progress || "pending..."}</span>;
  }
  //Is the upload being deleted?
  if (uploadDeleting) {
    return <span>Upload: deleting...</span>;
  }
  // Are we downloading?
  if (needsDownload) {
    return <span>Download: {status.progress || "pending..."}</span>;
  }
  // Did we ever upload, and ready?
  if (uploaded) {
    return (
      <span>
        <Popup
          trigger={<span>yes</span>}
          content={
            <div>
              <div>Uploaded: {moment(uploadedAt).fromNow()}</div>
              <div>Duration: {moment.duration(moment(bu.upload_updated_at).diff(uploadedAt)).humanize()}</div>
              <div>Size: {humanizeFileSize(uploadSize)}</div>
              <br />
              <div>Downloaded: {downloadedAt ? moment(downloadedAt).fromNow() : "never"}</div>
              <div>Duration: {downloaded ? moment.duration(moment(startDownload).diff(downloadedAt)).humanize() : "-"}</div>
            </div>
          }
        />
      </span>
    );
  }

  return <span>no</span>;
};

const StatusValueView = ({ ...args }: ICardValueView) => {
  const status = args.status;
  const state = status.state;
  if (status.upload_only) {
    return <span>Upload only</span>;
  }
  if (!state) {
    return <span>-</span>;
  }
  return <Popup trigger={<span>{status.state}</span>} content={<div>{status.message || "-"}</div>} />;
};

const CreatedValueView = ({ ...args }: ICardValueView) => {
  const debug = window.DEBUG;
  const bu = args.bu;
  const status = args.status;
  return (
    <Popup
      trigger={<span>{bu.created_at ? moment(bu.created_at).fromNow() : "-"}</span>}
      content={
        <div>
          {debug && <div>ID: {bu.id}</div>}
          <div>Name: {bu.name || ""}</div>
          <div>Created: {status.created_at ? moment(status.created_at).fromNow() : "pending..."}</div>
          <div>Date: {status.created_at ? moment(status.created_at).toString() : "pending..."}</div>
        </div>
      }
    />
  );
};

const AutoDeleteValueView = ({ ...args }: ICardValueView) => {
  const bu = args.bu;
  return (
    <span>
      {bu.auto_deleted_at ? (
        <Popup trigger={<span>{moment(bu.auto_deleted_at).fromNow()}</span>} content={<div>Date: {moment(bu.auto_deleted_at).toString()}</div>} />
      ) : (
        "never"
      )}
    </span>
  );
};

const DbVersionValueView = ({ ...args }: ICardValueView) => {
  const bu = args.bu;
  const deplInfo = bu.deployment_info;
  const servers = (deplInfo && deplInfo.servers) || {};
  return <span>{deplInfo ? <Popup trigger={<span>{deplInfo.version}</span>} content={<div>DB Servers: {servers.dbservers || "-"}</div>} /> : "-"}</span>;
};

const SizeValueView = ({ ...args }: ICardValueView) => {
  const bu = args.bu;
  const status = bu.status || {};
  const uploadStatus = status.upload_status;
  const uploaded = uploadStatus && uploadStatus.uploaded;
  const uploadSize = (uploadStatus && uploadStatus.size_bytes) || 0;
  // If we have a fully uploaded backup, use that size
  if (uploaded) {
    return <span>{humanizeFileSize(uploadSize)}</span>;
  }
  return <span>{status.size_bytes ? humanizeFileSize(status.size_bytes) : "-"}</span>;
};

interface ICardRestorableValueView extends ICardValueView {
  isRestorePossible: boolean;
  sameMajorMinorVersion: boolean;
  sameNumberOfDbServers: boolean;
  isDownloadPossible: boolean;
}

const RestorableValueView = ({ ...args }: ICardRestorableValueView) => {
  const bu = args.bu;
  const status = bu.status || {};
  // Do we have a status?
  if (!bu.status) {
    return <span>-</span>;
  }
  // Is it restorable?
  if (args.isRestorePossible) {
    return <span>yes</span>;
  }
  // Show the reason, more important reasons are lower in this list
  let reason = "Unknown";
  if (args.isDownloadPossible) {
    reason = "Backup should be downloaded first";
  }
  if (!args.sameNumberOfDbServers) {
    reason = "Number of servers in backup and currently running cluster not the same";
  }
  if (!args.sameMajorMinorVersion) {
    reason = "Major and minor version in backup and currently running cluster not the same";
  }
  if (status.is_failed) {
    reason = `Backup creation failed: ${status.message}`;
  }
  return (
    <span>
      <Popup trigger={<span>no</span>} content={<div>Reason: {reason}</div>} />
    </span>
  );
};

interface IRowViewArgs extends IWithRefreshProps {
  deployment: ApiDeployment;
  item: ApiBackup;
  hasBackupPermission: (url: string | undefined, permission: Permission) => boolean;
  onClickRestore: () => void;
  onClickEdit: () => void;
  onClickDownload: () => void;
  onClickDelete: () => void;
  onClickCloneDeploymentFromBackup: (needsNewTC: boolean) => void;
  onResumeDeployment: () => void;
  currentTC?: ApiTermsAndConditions;
  acceptedTC?: string;
  showAcceptTermsAndConditions: boolean;
  onClickCancelShowAcceptTermsAndConditions: () => void;
  onClickTriggerShowAcceptTermsAndConditions: () => void;
  requiresAcceptanceOfTermsAndConditions: () => boolean;
  isMultiRegionBackupEnabled?: boolean;
}

const RowView = ({ ...args }: IRowViewArgs) => {
  const { isCopyBackupAllowed } = useDeploymentPermissions();
  const bu = args.item;
  const isDeleted = !!bu.is_deleted;
  const isRestoreAllowed = !bu.is_deleted && args.hasBackupPermission(bu.url, "backup.backup.restore");
  const isUpdateAllowed = !bu.is_deleted && args.hasBackupPermission(args.item.url, "backup.backup.update");
  const isDownloadAllowed = !bu.is_deleted && args.hasBackupPermission(bu.url, "backup.backup.download");
  const isDeleteAllowed = !bu.is_deleted && args.hasBackupPermission(bu.url, "backup.backup.delete");
  const isCloneDeploymentAllowed = !bu.is_deleted && args.hasBackupPermission(bu.url, "replication.deployment.clone-from-backup");
  const status = bu.status || {};
  const versionRunning = new semver.SemVer(args.deployment.version || "");
  const versionBackup = new semver.SemVer((args.item.deployment_info && args.item.deployment_info.version) || "");
  const sameMajorMinorVersion = versionRunning.major == versionBackup.major && versionRunning.minor == versionBackup.minor;
  const isMultiRegionBackup = !!bu.source_backup_id;
  // Restore is possible if the backup is available, the major and minor version are the same and the backup is not a multi region backup
  const isRestorePossible = !!status.available && sameMajorMinorVersion && !isMultiRegionBackup;
  const uploadStatus = status.upload_status || {};
  const numberOfDbServersRunning = (args.deployment.servers && args.deployment.servers.dbservers) || 0;
  const numberOfDbServersBackup = (args.item.deployment_info && args.item.deployment_info.servers && args.item.deployment_info.servers.dbservers) || 0;
  const sameNumberOfDbServers = numberOfDbServersRunning == numberOfDbServersBackup;
  const isDownloadPossible = !status.available /* no need to, it's available */ && !!uploadStatus.uploaded /* not uploaded */ && sameNumberOfDbServers;
  const isClonePossible = !!(status.upload_status && status.upload_status.uploaded);

  const [openCopyToMultiRegionModal, toggleMultiRegionBackupModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState<ApiRegion>({});

  const loadRegionDetails = async (regionID: string) => {
    if (!regionID) return "-";

    setLoading(true);

    try {
      const req: ApiIDOptions = { id: regionID };
      const regionName = await apiClients.platformClient.GetRegion(req);
      setRegion(regionName);
    } catch (err) {
      console.warn(err);
      setRegion({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRegionDetails(bu.region_id || "");
  }, []);

  return (
    <Table.Row>
      <Table.Cell>{bu.id || "-"}</Table.Cell>
      {args.isMultiRegionBackupEnabled && <Table.Cell>{bu.source_backup_id || "-"}</Table.Cell>}
      <Table.Cell>
        <CreatedValueView bu={bu} status={status} />
      </Table.Cell>
      <Table.Cell>
        <StatusValueView bu={bu} status={status} />
      </Table.Cell>
      <Table.Cell>{bu.backup_policy_id ? "Scheduled" : "Manual"}</Table.Cell>
      <Table.Cell>
        <InCloudStorageValueView bu={bu} status={status} />
      </Table.Cell>
      <Table.Cell>
        {loading ? <Loader active inline size="tiny" /> : region.location ? region.location : useDeploymentStore.getState().region.location}
      </Table.Cell>
      <Table.Cell>
        <SizeValueView bu={bu} status={status} />
      </Table.Cell>
      <Table.Cell>
        <AutoDeleteValueView bu={bu} status={status} />
      </Table.Cell>
      <Table.Cell>
        <RestorableValueView
          bu={bu}
          status={status}
          isRestorePossible={isRestorePossible}
          sameMajorMinorVersion={sameMajorMinorVersion}
          sameNumberOfDbServers={sameNumberOfDbServers}
          isDownloadPossible={isDownloadPossible}
        />
      </Table.Cell>
      <Table.Cell>
        <DbVersionValueView bu={bu} status={status} />
      </Table.Cell>
      <Table.Cell>{isDeleted ? moment(bu.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        {!isDeleted && (
          <div className="table-action-buttons">
            {isRestoreAllowed && isRestorePossible && (
              <DeploymentPausedModal
                {...args}
                onClick={args.onClickRestore}
                trigger={<ListAction icon="undo" tooltip="Restore backup. Note that this will overwrite ALL existing data in the deployment!" />}
              />
            )}
            {isCloneDeploymentAllowed && isClonePossible && (
              <CloneAcceptTermsAndConditionsModal
                {...args}
                is_paused={!!args.deployment.is_paused}
                termsAndConditions={args.currentTC}
                acceptedTC={args.acceptedTC}
                onClick={args.onClickCloneDeploymentFromBackup}
                showAcceptTermsAndConditions={args.showAcceptTermsAndConditions}
                onCancelShowAcceptTermsAndConditions={args.onClickCancelShowAcceptTermsAndConditions}
                trigger={<ListAction icon="clone" tooltip="Clone backup to new deployment" onClick={args.onClickTriggerShowAcceptTermsAndConditions} />}
                requiresAcceptanceOfTermsAndConditions={args.requiresAcceptanceOfTermsAndConditions}
              />
            )}
            {args.isMultiRegionBackupEnabled && isCopyBackupAllowed && bu.upload && (
              <>
                <ListAction
                  icon="sitemap"
                  tooltip="Copy backup to a new region"
                  onClick={() => {
                    toggleMultiRegionBackupModal(true);
                  }}
                />
                {openCopyToMultiRegionModal && (
                  <CopyBackupToDifferentRegion
                    {...args}
                    sourceID={bu.id || ""}
                    deploymentRegion={args.deployment.region_id || ""}
                    onClose={() => {
                      toggleMultiRegionBackupModal(false);
                    }}
                  />
                )}
              </>
            )}
            {isUpdateAllowed && <DeploymentPausedModal {...args} onClick={args.onClickEdit} trigger={<ListAction icon="edit" tooltip="Edit backup" />} />}
            {isDownloadAllowed && isDownloadPossible && (
              <DeploymentPausedModal
                {...args}
                onClick={args.onClickDownload}
                trigger={<ListAction icon="cloud download" tooltip="Download backup to deployment servers" />}
              />
            )}
            {isDeleteAllowed && <DeploymentPausedModal {...args} onClick={args.onClickDelete} trigger={<ListAction icon="trash" tooltip="Delete backup" />} />}
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

interface IListViewArgs extends IWithRefreshProps {
  deployment: ApiDeployment;
  items: ApiBackup[];
  loading: boolean;
  hasBackupPermission: (url: string | undefined, permission: Permission) => boolean;
  onClickRestore: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickDownload: (id: string) => void;
  onClickDelete: (id: string) => void;
  onClickCloneDeploymentFromBackup: (id: string, needsNewTC: boolean) => void;
  onResumeDeployment: () => void;
  currentTC?: ApiTermsAndConditions;
  acceptedTC?: string;
  showAcceptTermsAndConditions: boolean;
  onClickCancelShowAcceptTermsAndConditions: () => void;
  onClickTriggerShowAcceptTermsAndConditions: () => void;
  requiresAcceptanceOfTermsAndConditions: () => boolean;
  isMultiRegionBackupEnabled?: boolean;
}

const ListView = ({ ...args }: IListViewArgs) => {
  return (
    <Table striped>
      <Table.Header>
        <Table.HeaderCell>ID</Table.HeaderCell>
        {args.isMultiRegionBackupEnabled && (
          <Table.HeaderCell>
            Copied from Backup
            <Popup trigger={<Icon name="info circle" color="grey" />} content="The source backup ID from which it is copied." />{" "}
          </Table.HeaderCell>
        )}
        <Table.HeaderCell>Created</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>In Cloud Storage</Table.HeaderCell>
        <Table.HeaderCell>Region</Table.HeaderCell>
        <Table.HeaderCell>Size</Table.HeaderCell>
        <Table.HeaderCell>Auto-delete</Table.HeaderCell>
        <Table.HeaderCell>Restorable</Table.HeaderCell>
        <Table.HeaderCell>DB Version</Table.HeaderCell>
        <Table.HeaderCell>Deleted</Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickRestore={() => args.onClickRestore(item.id || "")}
            onClickEdit={() => args.onClickEdit(item.id || "")}
            onClickDownload={() => args.onClickDownload(item.id || "")}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickCloneDeploymentFromBackup={(needsNewTC: boolean) => args.onClickCloneDeploymentFromBackup(item.id || "", needsNewTC)}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No backups inside this deployment</div>;

// Interface describing the backup list view arguments
export interface IBackupListViewArgs extends IWithRefreshProps, RouteComponentProps {
  loading: boolean;
  deployment: ApiDeployment;
  backups?: ApiBackupList;
  currentTC?: ApiTermsAndConditions;
  acceptedTC?: string;
  showAcceptTermsAndConditions: boolean;
  onClickCancelShowAcceptTermsAndConditions: () => void;
  onClickTriggerShowAcceptTermsAndConditions: () => void;
  requiresAcceptanceOfTermsAndConditions: () => boolean;
  onClickBackupRestore: (id: string) => void;
  onClickBackupEdit: (id: string) => void;
  onClickBackupDownload: (id: string) => void;
  onClickBackupDelete: (id: string) => void;
  onClickCloneDeploymentFromBackup: (id: string, needsNewTC: boolean) => void;
  onResumeDeployment: () => void;

  isMultiRegionBackupEnabled?: boolean;
}

export const BackupListView = ({ ...args }: IBackupListViewArgs) => {
  if (!args.backups) {
    return <Loading />;
  }
  if (_.isEmpty(args.backups.items)) {
    return <EmptyView />;
  }
  return (
    <ListView
      {...args}
      items={args.backups.items || []}
      loading={args.loading}
      onClickRestore={args.onClickBackupRestore}
      onClickEdit={args.onClickBackupEdit}
      onClickDownload={args.onClickBackupDownload}
      onClickDelete={args.onClickBackupDelete}
      onClickCancelShowAcceptTermsAndConditions={args.onClickCancelShowAcceptTermsAndConditions}
      hasBackupPermission={(url: string | undefined, permission: Permission) => {
        return args.hasPermissionByUrl ? args.hasPermissionByUrl(url || "", ResourceType.Backup, permission) : false;
      }}
    />
  );
};
